
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.telephone = window.Smartly.locales.modules.en.back_royal.telephone || {};
window.Smartly.locales.modules.en.back_royal.telephone.tel_input = window.Smartly.locales.modules.en.back_royal.telephone.tel_input || {};
window.Smartly.locales.modules.en.back_royal.telephone.tel_input = {...window.Smartly.locales.modules.en.back_royal.telephone.tel_input, ...{
    "invalid_phone": "Invalid phone number",
    "extension_placeholder": "Ext.",
    "please_enter_your_phone_number": "Please enter your phone number"
}
}
    