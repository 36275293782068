import { cva, type VariantProps } from 'class-variance-authority';
import { Slot } from '@radix-ui/react-slot';
import { type ReactNode } from 'react';

const typographyStyles = cva(null, {
    variants: {
        variant: {
            header1:
                'tw-text-2xl tw-font-medium tw-leading-[34px] tw-tracking-[0.24px] md:tw-text-3xl md:tw-leading-10 md:tw-tracking-[0.3px]',
            header2: 'tw-text-md tw-font-medium tw-leading-7 md:tw-text-xl md:tw-leading-7.5',
            header3: 'tw-text-md tw-font-bold tw-leading-7 md:tw-text-lg',
            body1: 'tw-text-md tw-leading-7',
            body2: 'tw-text-base tw-leading-7.5',
            body3: 'tw-text-xs tw-leading-7 md:tw-text-sm md:tw-leading-[17px]',
            body4: 'tw-text-sm tw-leading-5',
            smallText: 'tw-text-xs tw-leading-5.5',
            link: 'tw-text-xs tw-leading-[17px]',
        },
    },
    defaultVariants: {
        variant: 'body1',
    },
});

function getDefaultTag(variant?: TypographyProps['variant']) {
    switch (variant) {
        case 'header1':
            return 'h1';
        case 'header2':
            return 'h2';
        case 'header3':
            return 'h3';
        case 'body1':
        case 'body2':
        case 'body3':
        case 'body4':
        case 'smallText':
            return 'p';
        case 'link':
            return 'span';
        default:
            return 'p';
    }
}

type TypographyProps = { children?: ReactNode; className?: string; asChild?: boolean } & VariantProps<
    typeof typographyStyles
>;

export function Typography({ variant, className, asChild, ...props }: TypographyProps) {
    const Comp = asChild ? Slot : getDefaultTag(variant);
    return <Comp className={typographyStyles({ variant, className })} {...props} />;
}
