import { useEffect, useState } from 'react';

export default function useIsMobileMixin() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [xsOrSm, setXsOrSm] = useState(window.innerWidth < 992);

    useEffect(() => {
        const func = () => {
            setIsMobile(window.innerWidth < 768);
            setXsOrSm(window.innerWidth < 992);
        };

        func();

        // This timeout runs after 300ms because of specs. This value needs to be lower than
        // @testing-library/react's asyncUtilTimeout because we're using a waitFor operation
        // in the spec to assert the callback sets the state we'd expect.
        const handler = () => setTimeout(func, 300);

        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    return { isMobile, xsOrSm };
}
