import {
    useMemo,
    useState,
    useEffect,
    useRef,
    type AnchorHTMLAttributes,
    type ImgHTMLAttributes,
    type HTMLAttributes,
} from 'react';
import clsx from 'clsx';
import { sortBy } from 'lodash/fp';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import rehypeExternalLinks from 'rehype-external-links';
import 'katex/dist/katex.min.css';
import { type LinkToLessonInStream, lessonsApi } from 'Lessons';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { IconButton } from '@mui/material';
import { Replay } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import { useTheme } from 'Theme';
import { useSelector } from 'react-redux';
import { getStreamingAiMessage } from 'TutorBot/redux/chat';
import { type ThemeSliceState } from 'Theme/redux/theme';
import { MessageEvaluations } from './MessageEvaluations';
import {
    isLessonFrameSourceLocation,
    isDisplayableNonLessonFrameSourceLocation,
    isLinkToLessonInStream,
    getUrlForLink,
    isHelpscoutSourceLocation,
} from '../utils/sourceLocationHelpers';
import {
    getMessageSources,
    isAiMessage,
    isHumanMessage,
    isPretendAiMessage,
    isRealAiMessage,
} from '../utils/ChatMessage';
import { addFootnotesToMessage } from '../utils/addFootnotesToMessage';

import MessageSources from './MessageSources';
import {
    type StudentMessageEvaluation,
    type ChatMessage,
    type SourceMetadata,
    type DisplayableNonLessonFrameSourceLocation,
    type SourceLink,
    type RealAiMessage,
} from '../TutorBot.types';
import { helpScoutCollectionTitle, linkStyles } from './MessageSources/shared';
import { formatMathInMessage } from '../utils/formatMathInMessage';

type Props = {
    message: ChatMessage;
    showPretendLoadingSpinner?: boolean;
    className?: string;
    onThumbClick?: (messageId: string, label: StudentMessageEvaluation['label']) => void;
    retryClick?: () => void;
};

const { useGetLinksToLessonsInStreamsQuery } = lessonsApi;

const getMessageContentClasses = (
    message: ChatMessage,
    isLoadingMessage: boolean,
    bgColor: ThemeSliceState['bgColor'],
) =>
    clsx(
        'tw-max-w-[75%] tw-select-text tw-rounded-[10px] tw-px-3.5 tw-py-2.5 tw-flex tw-flex-col tw-leading-5 sm:tw-leading-6',
        isHumanMessage(message) && 'tw-ml-auto tw-text-white  rtl:tw-ml-0 rtl:tw-mr-auto',
        isHumanMessage(message) && {
            'tw-bg-gradient-to-r tw-from-[#FF4D63] tw-to-[#FE5F5F] quantic:tw-bg-gradient-to-r quantic:tw-from-[#FF4D63] quantic:tw-to-[#FE5F5F] valar:tw-bg-gradient-to-r valar:tw-from-blue valar:tw-to-blue valar:tw-bg-blue':
                ['beige', 'white', 'beige-pattern', 'demo-pattern'].includes(bgColor),
            'tw-bg-gradient-to-r tw-from-[#349391] tw-to-[#29B8A0]': bgColor === 'turquoise',
            'tw-bg-gradient-to-r tw-from-[#8F58E0] tw-to-[#A968E1]': bgColor === 'purple',
            'tw-bg-gradient-to-r tw-from-[#366CCB] tw-to-[#507EFB]': bgColor === 'blue',
        },
        isLoadingMessage && 'tw-py-[13px]',
        isAiMessage(message) &&
            'tw-border tw-border-tutorbot-border tw-bg-white tw-text-tutorbot-chat tw-shadow-smallish',
    );

const LoadingSpinner = () => <FrontRoyalSpinner className="no-top-margin no-delay static-height" />;

const ErrorMessage = ({ message, retryClick }: { message: ChatMessage; retryClick: () => void }) => (
    <IconButton onClick={retryClick} disableRipple>
        {message.content}
        <Replay className="tw-text-green" />
    </IconButton>
);

const LoadingStatusMessage = ({ status }: { status: RealAiMessage['status'] }) => {
    const { t } = useTranslation('back_royal');
    const statusText = t([`chat.chat.${status?.key}`, ''], status?.params ?? {}) ?? null;
    return (
        <Transition
            show={!!statusText}
            enter="tw-transition-all tw-duration-500 tw-ease-out"
            enterFrom="tw-opacity-0 -tw-translate-x-1"
            enterTo="tw-opacity-100 tw-translate-x-0"
            leave="tw-transition-all tw-duration-200 tw-ease-in"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
        >
            <span className="tw-text-tutorbot-chat tw-opacity-60">{statusText}</span>
        </Transition>
    );
};

const MarkDownLink = ({ children, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <a {...rest} className={linkStyles}>
        {children}
    </a>
);

// see comment down inside the definition of preparedMessageContent
const ImgWithStreamingCursorSupport = ({ alt, ...rest }: ImgHTMLAttributes<HTMLImageElement>) => {
    if (alt === 'StreamingCursor') {
        return (
            <span>
                &nbsp;
                <span className="-tw-mb-[2px] tw-inline-block tw-h-[1em] tw-w-[0.5em] tw-bg-tutorbot-chat tw-opacity-50" />
            </span>
        );
    }

    return <img {...rest} alt={alt} />;
};

const MarkDownUL = ({ children }: HTMLAttributes<HTMLElement>) => (
    <ul className="tw-my-5 tw-ms-5 tw-list-disc tw-space-y-2">{children}</ul>
);

const MarkDownOL = ({ children }: HTMLAttributes<HTMLElement>) => (
    <ol className="tw-my-5 tw-ms-5 tw-list-decimal tw-space-y-2">{children}</ol>
);

const MarkDownStrong = ({ children }: HTMLAttributes<HTMLElement>) => (
    <strong className="tw-font-semibold">{children}</strong>
);

const MarkDownParagraphHumanMessage = ({ children }: HTMLAttributes<HTMLElement>) => (
    <p className="tw-mb-3 last:tw-mb-0">{children}</p>
);

// We must override the color for certain elements that match differently styled selectors in scss
const MarkDownParagraphAiMessage = ({ children }: HTMLAttributes<HTMLElement>) => (
    <p className="tw-mb-3 tw-text-tutorbot-chat last:tw-mb-0">{children}</p>
);

function getLessonIdForSource({
    source,
    lessonLinksForUser,
}: {
    source: SourceMetadata;
    lessonLinksForUser: Record<string, LinkToLessonInStream>;
}) {
    // I had to use this for loop to get around typescript errors. I'm sure there's a cleaner way
    for (let i = 0; i < source.locations.length; i++) {
        const loc = source.locations[i];
        if (isLessonFrameSourceLocation(loc)) {
            const lessonId = loc.lessonId;
            if (lessonLinksForUser[lessonId]) return lessonId;
        }
    }

    return null;
}

// In the case of lesson frame locations, the link for a source is an instance of LinkToLessonInStream.
// In the case of other locations, the link is just the location itself, since it has a url property.
function getLinksFromSource(
    source: SourceMetadata,
    lessonLinksForUser: Record<string, LinkToLessonInStream>,
): (LinkToLessonInStream | DisplayableNonLessonFrameSourceLocation)[] {
    const lessonId = getLessonIdForSource({ source, lessonLinksForUser });
    const linkToLessonInStream = lessonId && lessonLinksForUser[lessonId];
    if (linkToLessonInStream) return [linkToLessonInStream];

    return source.locations.filter(loc =>
        isDisplayableNonLessonFrameSourceLocation(loc),
    ) as DisplayableNonLessonFrameSourceLocation[];
}

function getSortKeyForLink(sourceLink: SourceLink) {
    const link = sourceLink.link;
    if (isLinkToLessonInStream(link)) return `${link.streamTitle}${link.lessonIndexInStream}`;
    if (isHelpscoutSourceLocation(link)) return `${helpScoutCollectionTitle(link.collectionId)}${link.title}`;
    if (isDisplayableNonLessonFrameSourceLocation(link)) return link.title;
    return ''; // you can't actually get here
}

export function Message({ message, className, retryClick, showPretendLoadingSpinner = false }: Props) {
    const { data: lessonLinksForUser } = useGetLinksToLessonsInStreamsQuery();

    // We have some hard-coded messages that we'd like to present as if they were being
    // generated via Tutorbot - in those cases, we can use the pretendToLoadMessage property
    // here to "pretend" that we're spending time retrieving the message.
    const [isPretendLoading, setIsPretendLoading] = useState(showPretendLoadingSpinner);
    const isLoadingBotMessage = message.role === 'ai' && !message.hasError && !message.complete && !message.content;
    const { bgColor } = useTheme();
    const streamingAiMessage = useSelector(getStreamingAiMessage);
    const isStreamingAiMessage = streamingAiMessage?.id === message.id;
    const [isStreamingDelay, setIsStreamingDelay] = useState(false);
    const streamingDelayTimeoutId = useRef<NodeJS.Timeout | null>(null);
    const isLoading = isPretendLoading || isLoadingBotMessage || isStreamingDelay;

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isPretendAiMessage(message) && isPretendLoading) {
            timeoutId = setTimeout(() => {
                setIsPretendLoading(false);
            }, 750);
        }

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [message, isPretendLoading]);

    // Show the loading spinner if it's been more than 2 seconds since the last token was added to the message
    useEffect(() => {
        setIsStreamingDelay(false);

        if (streamingDelayTimeoutId.current) {
            clearTimeout(streamingDelayTimeoutId.current);
        }

        const timeoutId: NodeJS.Timeout = setTimeout(() => {
            if (isStreamingAiMessage) {
                setIsStreamingDelay(true);
            }
        }, 2000);

        streamingDelayTimeoutId.current = timeoutId;

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isStreamingAiMessage, message.content]);

    const isFromTutorBot = message.role === 'ai';
    const isError = message.id === 'error-message' && !!retryClick;

    const shouldShowEvaluations = !isPretendAiMessage(message) && isFromTutorBot && !isLoadingBotMessage;

    const sourceLinks: SourceLink[] = useMemo(() => {
        if (!lessonLinksForUser) {
            return [];
        }

        const sources = getMessageSources(message);

        const unsortedLinks: Record<string, SourceLink> = {};
        sources.forEach((source: SourceMetadata) => {
            getLinksFromSource(source, lessonLinksForUser).forEach(link => {
                const url = getUrlForLink(link);
                if (!url) return;
                if (!unsortedLinks[url]) {
                    unsortedLinks[url] = {
                        sourceIds: [],
                        link,
                    };
                }
                if (source.id) {
                    unsortedLinks[url].sourceIds.push(source.id);
                }
            });
        });

        return sortBy((link: SourceLink) => getSortKeyForLink(link), Object.values(unsortedLinks)) as SourceLink[];
    }, [message, lessonLinksForUser]);

    const preparedMessageContent = useMemo(() => {
        if (!message.content) return null;
        const footnoteReferences = isRealAiMessage(message) ? message.footnoteReferences : {};
        let messageContent = addFootnotesToMessage(message.content, footnoteReferences, sourceLinks);
        messageContent = formatMathInMessage(messageContent);

        // In order to get the cursor to display inline, it has to be injected into the markdown. We do
        // this by hacking the handling of the image tag
        const showCursor = isStreamingAiMessage && !message.complete;
        if (showCursor) {
            messageContent += '![StreamingCursor](StreamingCursor)';
        }

        return messageContent;
    }, [message, sourceLinks, isStreamingAiMessage]);

    return (
        <div className={clsx('tw-mb-2 tw-flex tw-w-full sm:tw-mb-5', className)} data-testid="message">
            <div data-testid="message-content" className={getMessageContentClasses(message, isLoading, bgColor)}>
                {isError ? (
                    <ErrorMessage retryClick={retryClick} message={message} />
                ) : (
                    <div>
                        {!isPretendLoading && preparedMessageContent && (
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm, [remarkMath, { singleDollarTextMath: false }]]}
                                rehypePlugins={[rehypeKatex, [rehypeExternalLinks, { target: '_blank' }]]}
                                components={{
                                    a: MarkDownLink,
                                    img: ImgWithStreamingCursorSupport,
                                    ol: MarkDownOL,
                                    ul: MarkDownUL,
                                    strong: MarkDownStrong,
                                    p: isHumanMessage(message)
                                        ? MarkDownParagraphHumanMessage
                                        : MarkDownParagraphAiMessage,
                                }}
                            >
                                {preparedMessageContent}
                            </ReactMarkdown>
                        )}
                    </div>
                )}

                {sourceLinks.length > 0 && <MessageSources sourceLinks={sourceLinks} />}

                {isLoading && (
                    <div
                        className={clsx(
                            'tw-flex tw-flex-wrap tw-items-center tw-justify-start tw-gap-4 tw-self-start',
                            message.content?.length && !isPretendLoading && 'tw-py-3',
                        )}
                    >
                        <LoadingSpinner />
                        {isRealAiMessage(message) && <LoadingStatusMessage status={message.status} />}
                    </div>
                )}
            </div>
            {shouldShowEvaluations && <MessageEvaluations messageId={message.id} />}
        </div>
    );
}
