import { formattedTelephoneNumber } from 'Telephone/TelephoneFormatter';

export default function telephoneValidation() {
    return {
        require: 'ngModel',
        link(scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(inputValue => {
                const transformedInput = formattedTelephoneNumber(inputValue, attrs.countryCode);

                if (transformedInput !== inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }

                return transformedInput;
            });
        },
    };
}
