import { type Dispatch, type FC, type SetStateAction } from 'react';
import { type StreamIguanaObject } from 'Lessons';
import { useSelector } from 'react-redux';
import { getHasChangedTopic } from 'TutorBot/redux/chat';
import { useLockedAiAdvisorConfig } from '../useLockedAiAdvisorConfig';
import { NewTopicMessage } from './NewTopicMessage';
import { InitialGreetingMessage } from './InitialGreetingMessage';
import { SubsequentGreetingMessage } from './SubsequentGreetingMessage';
import { InProgressExamMessage } from './InProgressExamMessage';
import { LockedAiAdvisorMessage } from './LockedAiAdvisorMessage';
import { type BotClientContext } from '../TutorBot.types';

type Props = {
    name: string;
    hasSeenInitialGreeting: boolean | undefined;
    setHasSeenInitialGreeting: Dispatch<SetStateAction<boolean | undefined>>;
    inProgressExam: StreamIguanaObject | undefined;
    uiContext: BotClientContext['uiContext'];
};

export const GreetingMessage: FC<Props> = ({
    name,
    hasSeenInitialGreeting,
    setHasSeenInitialGreeting,
    inProgressExam,
    uiContext,
}) => {
    const { isLocked } = useLockedAiAdvisorConfig(uiContext);
    const hasChangedTopic = useSelector(getHasChangedTopic);

    return isLocked ? (
        <LockedAiAdvisorMessage />
    ) : inProgressExam ? (
        <InProgressExamMessage name={name} examTitle={inProgressExam.title} uiContext={uiContext} />
    ) : !hasSeenInitialGreeting ? (
        <InitialGreetingMessage name={name} setHasSeenInitialGreeting={setHasSeenInitialGreeting} />
    ) : hasChangedTopic ? (
        <NewTopicMessage />
    ) : (
        <SubsequentGreetingMessage name={name} />
    );
};
