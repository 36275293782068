import deepmerge from 'deepmerge';

// See webpack/loaderHelpers/README.md for an overview of how locales are built
export default function setSpecLocales(...objs) {
    if (objs.length > 1) {
        objs.forEach(obj => setSpecLocales(obj));
        return;
    }
    const obj = objs[0];

    // obj looks like {en: {my_module: {my_file: {key: 'Translation'}}}}
    window.Smartly = window.Smartly || {};
    window.Smartly.locales = window.Smartly.locales || {};
    window.Smartly.locales.modules = window.Smartly.locales.modules || {};
    window.Smartly.locales.modules = deepmerge(window.Smartly.locales.modules, obj);
}
