import { determineAiAdvisorState } from './determineAiAdvisorState';
import { type AiAdvisorState, UnlockedAiAdvisorState, type BotClientContext } from './TutorBot.types';

export function determineAiAdvisorLocked(state: AiAdvisorState): boolean;
export function determineAiAdvisorLocked(
    uiContext: BotClientContext['uiContext'],
    $injector: ng.auto.IInjectorService,
): boolean;
export function determineAiAdvisorLocked(
    arg0: AiAdvisorState | BotClientContext['uiContext'],
    injector?: ng.auto.IInjectorService,
): boolean {
    let state: AiAdvisorState;
    if (injector) {
        state = determineAiAdvisorState(arg0 as BotClientContext['uiContext'], injector);
    } else {
        state = arg0 as AiAdvisorState;
    }
    return !Object.values(UnlockedAiAdvisorState).includes(state as UnlockedAiAdvisorState);
}
