import template from 'Telephone/angularModule/views/tel_input.html';
import { formattedTelephonePlaceholder } from 'Telephone';
import { parsePhoneNumber } from 'libphonenumber-js';

// See http://stackoverflow.com/a/14425022/1747491
export default function telephoneInput($injector) {
    const $timeout = $injector.get('$timeout');
    const TranslationHelper = $injector.get('TranslationHelper');
    const LocalizedCountries = $injector.get('LocalizedCountries');

    return {
        restrict: 'E',
        template,
        scope: {
            ngModel: '=',
            errors: '=?',
            includeExtension: '<?',
            extensionModel: '<?',
            defaultCountryCode: '<?',
            countryCode: '<?',
        },
        require: '?^ngModel',
        link(scope, elem, attrs, modelController) {
            scope.ERROR_FIELD = 'phone';
            scope.errors = scope.errors || {};

            const translationHelper = new TranslationHelper('telephone.tel_input');

            scope.countryOptions = _.chain(LocalizedCountries.getForCurrentLocale())
                .filter(option => option.text.length > 0)
                .map(option => {
                    option.formattedLabel = `${option.text} (${option.telPrefix})`;
                    option.countryCode = option.value;
                    return option;
                })
                .value();

            let defaultCode = scope.countryCode || scope.defaultCountryCode || '';
            let defaultLocalNumber = '';

            if (scope.ngModel) {
                const parsedPhoneNumber = parsePhoneNumber(scope.ngModel);
                defaultCode = parsedPhoneNumber.country;
                defaultLocalNumber = parsedPhoneNumber.formatNational();
            }

            scope.proxy = {
                countryCode: defaultCode,
                localNumber: defaultLocalNumber,
            };

            scope.$watch('countryCode', () => {
                if (scope.countryCode) {
                    scope.proxy.countryCode = scope.countryCode;
                }

                // re-validate phone number if country code changes
                scope.onChange();
            });

            scope.$watch('ngModel', () => {
                if (scope.ngModel && !scope.proxy.countryCode) {
                    const parsedPhoneNumber = parsePhoneNumber(scope.ngModel);
                    scope.proxy.countryCode = parsedPhoneNumber.country;
                    scope.proxy.localNumber = parsedPhoneNumber.formatNational();
                    const skipPhoneValidation = true;
                    // force the placeholder text to change to its localized version and skip the
                    // phone number validation that occurs in scope.checkErrors().
                    scope.onChange(skipPhoneValidation);
                } else if (!scope.ngModel && scope.isRequired) {
                    modelController.$setValidity('required', false);
                    scope.errors[scope.ERROR_FIELD] = translationHelper.get('please_enter_your_phone_number');
                }
            });

            scope.isRequired = angular.isDefined(attrs.required);

            scope.selectLabelForCode = countryCode => _.find(scope.countryOptions, { countryCode }).telPrefix;

            // When either the country or the local number changes we need to recompute the number sent in the form.
            // When the country changes we need to recompute the placeholder number
            scope.onChange = skipPhoneValidation => {
                // check to see if the countryCode has been provided, if not, revert to defaults
                if (!scope.proxy.countryCode) {
                    scope.proxy.countryCode = defaultCode;
                }

                const countryCode = scope.proxy.countryCode;

                // Recompute the placeholder for the country
                scope.placeholder = formattedTelephonePlaceholder(countryCode);

                // Get the telPrefix of the country selected
                const country = _.find(scope.countryOptions, {
                    value: countryCode,
                });
                const telPrefix = country ? country.telPrefix : '';

                // set the passed-in attribute
                if (countryCode && scope.proxy.localNumber) {
                    // parsePhoneNumber(...).number returns the E.164 number in string format
                    try {
                        scope.ngModel = parsePhoneNumber(telPrefix + scope.proxy.localNumber, countryCode).number;
                    } catch (error) {
                        // noop
                    }
                }

                scope.checkErrors(skipPhoneValidation);
            };

            scope.onExtensionChange = () => {
                if (scope.proxy.extension) {
                    scope.extensionModel = scope.proxy.extension;
                }
            };

            scope.checkErrors = skipPhoneValidation => {
                // If a value for ngModel is passed in and we don't skip this phone number validation on page load,
                // then the red validation highlighting shows up on the select prematurely.
                if (!skipPhoneValidation) {
                    // If the user enters a valid country code and a valid phone number, but then completely removes
                    // the number by highlighting it and hitting the backspace button, we still want to show the
                    // red validation highlighting on both the select and the phone input along with the error message.
                    const actualValue = $('input[name="phone"]').val();
                    if (scope.ngModel && actualValue === '' && scope.ngModel !== '') {
                        scope.ngModel = '';
                        scope.errors[scope.ERROR_FIELD] = translationHelper.get('invalid_phone');
                    }
                }

                // If the field is required and the user hasn't entered a number then flag the formController as not valid
                if (scope.isRequired && !scope.proxy.localNumber) {
                    modelController.$setValidity('required', false);
                    return;
                }

                let parsedPhoneNumber;
                try {
                    parsedPhoneNumber = parsePhoneNumber(scope.ngModel, scope.proxy.countryCode);
                } catch (error) {
                    // noop
                }

                // If the user has entered a number and it isn't valid
                if (scope.proxy.localNumber && (!parsedPhoneNumber || !parsedPhoneNumber.isValid())) {
                    scope.errors[scope.ERROR_FIELD] = translationHelper.get('invalid_phone');
                    modelController.$setValidity('invalid_phone', false);
                    return;
                }

                delete scope.errors[scope.ERROR_FIELD];
                modelController.$setValidity('required', true);
                modelController.$setValidity('invalid_phone', true);
            };

            // wait until the DOM is up-to-date
            $timeout(() => {
                scope.onChange();
            });
        },
    };
}
