export default function formattedDateForEvent(event, timezone) {
    if (event.multiDay) {
        return `${event.formattedStartDateTime('ddd, MMM D', timezone)} — ${event.formattedEndDateTime(
            'ddd, MMM D',
            timezone,
        )}`;
    }

    if (event.anonymized) {
        return event.formattedStartDateTime('ddd, MMMM D', timezone);
    }

    return event.formattedStartDateTime('ddd, MMMM D h:mm A z', timezone);
}
