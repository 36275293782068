import { AoiRecordType } from 'ProgramAoi';
import { type Subscription } from 'Subscription';
import {
    type TuitionContract,
    tuitionContractComplete,
    totalRemainingTuition,
    paymentPerInterval,
} from 'TuitionContract';
import { type CurrentUserIguanaObject, getAoiRecord, getTuitionContract } from 'Users';
import { isFullScholarship } from 'Scholarship';
import { ProgramInclusionStatus } from 'ProgramInclusion';
import { type AdmissionOffer, offeredAdmission, acceptedAdmissionOffer } from 'AdmissionOffer';
import { TuitionAndRegistrationSection } from './TuitionAndRegistrationSection.types';

// Finds the desired tuition/registration-related directive name for the user's current state.
export function getTuitionAndRegistrationSection(currentUser: CurrentUserIguanaObject) {
    if (!currentUser) return null;

    // Don't try to show the user anything on this page if they've been expelled
    const programInclusion = getAoiRecord(currentUser, AoiRecordType.ProgramInclusion);
    if (programInclusion?.status === ProgramInclusionStatus.WillNotGraduate) return null;

    // Note that we're using aoiRecordForActiveProgram to get the AdmissionOffer here because the _active_
    // AOI record might be a ProgramInclusion.
    const admissionOffer = getAoiRecord(currentUser, AoiRecordType.AdmissionOffer);
    const tuitionContract = getTuitionContract(currentUser);
    const subscription = tuitionContract?.subscription;

    // If the user just completed a checkout session, we want to show registeredManageBilling.
    // completedStripeCheckoutSession will be proactively unset when we know the user was registered
    // and is in a good payment state.
    //
    // For card payments, that happens nearly instantly.
    //
    // For ACH payments on the onetime plan (not ACH subscriptions), we give the contract a grace period
    // to give some time for that payment to settle. Payment settlement could be 3-7 days for an ACH payment.
    // Since completedStripeCheckoutSession is stored in local storage, there is a period of time between
    // completing a checkout session and payment settlement where a user could open /application_status
    // in a different browser and see the `empty` UI because their contract has a grace period.
    //
    // Fixing this would require more information than we currently have available in the data model.
    // See also: https://trello.com/c/udTwnYhP
    if (currentUser.completedStripeCheckoutSession) return TuitionAndRegistrationSection.registeredManageBilling;

    // If the AdmissionOffer doesn't have a CohortSectionOffer with selectablePaymentSituations, we should
    // render nothing for this user.
    if (
        admissionOffer?.cohortSectionOffers
            .map(cohortSectionOffer => cohortSectionOffer.selectablePaymentSituations)
            .flat().length === 0
    ) {
        return null;
    }

    // This code only supports learners who have either accepted an admission offer or who
    // have been offered admission.
    return (
        getOfferedAdmissionTuitionAndRegistrationSection(admissionOffer) ||
        getAcceptedAdmissionOfferTuitionAndRegistrationSection(admissionOffer, tuitionContract, subscription)
    );
}

function getOfferedAdmissionTuitionAndRegistrationSection(admissionOffer: AdmissionOffer | null) {
    if (!offeredAdmission(admissionOffer)) return null;

    // If the admissionOffer's only cohortSectionOffer is for a full scholarship, they need to register
    // but don't have a plan selection to make.
    // Note that this doesn't take into account a user having multiple CohortSectionOffers. For a time,
    // we supported a user being offered admission into both a global and regional section. We no longer
    // support this, so we shouldn't be in a situation where a user is offered admission and have more
    // than one CohortSectionOffer going forward.
    if (isFullScholarship(admissionOffer?.cohortSectionOffers[0]?.cumulativeOfferedScholarship))
        return TuitionAndRegistrationSection.cohortRegistrationFullScholarship;

    // If the user doesn't have a full scholarship, they need to register via cohortRegistrationPlanSelection
    return TuitionAndRegistrationSection.cohortRegistrationPlanSelection;
}

function getAcceptedAdmissionOfferTuitionAndRegistrationSection(
    admissionOffer: AdmissionOffer | null,
    tuitionContract: TuitionContract | null,
    subscription: Subscription | null | undefined,
) {
    if (!acceptedAdmissionOffer(admissionOffer)) return null;

    // If the user has a subscription, we always want to show registeredManageBilling.
    // Note that this also accounts for any subscription - even incomplete of past_due
    // ones. Those users need to go to registeredManageBilling to fix their situation.
    if (subscription) return TuitionAndRegistrationSection.registeredManageBilling;

    // Note that this comes before the tuitionContractComplete check below because full scholarship
    // users will end up with a complete contract.
    if (isFullScholarship(tuitionContract?.cumulativeScholarship)) return TuitionAndRegistrationSection.empty;

    // If the user has a complete contract, we should show them registeredManageBilling. Note
    // that this comes after the Full Scholarship check because users with Full Scholarships
    // will have complete TuitionContracts, but shouldn't see registeredManageBilling.
    if (tuitionContractComplete(tuitionContract)) return TuitionAndRegistrationSection.registeredManageBilling;

    // If the user is in a payment grace period, we don't need to show them
    // anything if they've accepted their admission offer.
    if (tuitionContract?.inPaymentGracePeriod) return TuitionAndRegistrationSection.empty;

    // If we get here, it means we have a user that is registered, still owes us money,
    // but doesn't have a subscription or an active grace period. We should show them
    // cohortRegistrationNoPlanSelection so they can pay.
    //
    // Some situations where it may be possible to reach here:
    //  * student was initially registered and made a payment, then got expelled, and later readmitted
    //  * student made a one-time payment that was fully refunded via Stripe Dashboard
    //  * student made a one-time ACH payment that failed
    //  * student's subscription was cancelled via Stripe Dashboard before the contract was complete
    //  * student was manually registered via RegisterStudent UMA but no corresponding payment or
    //    subscription was made, and then the grace period expired
    //
    // Note that we can only allow them to setup a new subscription if they owe at least a single
    // payment on their TuitionPlan. If they owe less than a single payment (which is a very unlikely
    // scenario), then they would end up overpaying when they set up a new subscription. See
    // SubscriptionConcern#on_invoice_create to see how we handle it if they owe some other
    // strange amount.
    if (totalRemainingTuition(tuitionContract) >= paymentPerInterval(tuitionContract))
        return TuitionAndRegistrationSection.cohortRegistrationNoPlanSelection;

    // If we get this far for someone who has accepted their admission offer, we don't
    // have the ability to handle their situation through the UI. This could be a user
    // who owes less than a single payment (or maybe some other state?).
    return TuitionAndRegistrationSection.specialCase;
}
