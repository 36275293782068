import angularModule from 'TimeoutHelpers/angularModule/scripts/timeout_helpers_module';

angularModule.factory('scopeTimeout', [
    '$timeout',

    $timeout => (scope, fn, delay, invokeApply) => {
        const timer = $timeout(fn, delay, invokeApply);
        scope.$on('$destroy', () => {
            $timeout.cancel(timer);
        });
        return timer;
    },
]);
