// If there are any places where %% is included in the message content
// it is likely formatted math and we should replace the %% with $ so our markdown plugin can render it correctly
export const formatMathInMessage = (messageContent: string) => {
    // if we somehow get %%$ in a string, we need to add a 0 width space before we replace all %% with $$ below
    // otherwise we can end up in an opened and not closed math block and it seems to blow up formatting
    messageContent = messageContent.replace(/(%%)(\$)/g, `%%${'\u200b'}$`);
    messageContent = messageContent.replace(/%%/g, '$$$');

    return messageContent;
};
