import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getActiveConversationMessages, getAiMessageStreaming } from '../redux/chat';

export function useScrollToBottomOfChat() {
    const chatWindowRef = useRef<HTMLDivElement | null>(null);
    const chatMessagesContainerRef = useRef<HTMLDivElement | null>(null);
    const chatHistory = useSelector(getActiveConversationMessages);

    const aiMessageStreaming = useSelector(getAiMessageStreaming);

    //---------------------------
    // Scroll to bottom on new message
    //---------------------------

    const scrollToBottomOfChat = useCallback(() => {
        // Only use scrollTo if browser supports it and when not in the middle of a
        // streaming bot response. The smooth scroll can cause jitters while streaming.
        if (chatWindowRef.current?.scrollTo && !aiMessageStreaming) {
            chatWindowRef.current.scrollTo({
                top: chatWindowRef.current.scrollHeight,
                behavior: 'smooth',
            });
        } else if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [chatWindowRef, aiMessageStreaming]);

    // Any time a new message comes into the chat window, it increases the height
    // of the chatMessagesContainerRef. We attach a ResizeObserver so that whenever
    // that happens, we automatically scroll down to the bottom of chat.
    useEffect(() => {
        let resizeObserver: ResizeObserver;
        let windowResizeListener: () => void;

        if (chatMessagesContainerRef.current) {
            resizeObserver = new ResizeObserver(() => {
                scrollToBottomOfChat();
            });

            resizeObserver.observe(chatMessagesContainerRef.current);

            // this one is necessary for when keyboard pops up in Cordova
            windowResizeListener = () => {
                scrollToBottomOfChat();
            };

            window.addEventListener('resize', windowResizeListener);
        }

        return () => {
            if (resizeObserver) resizeObserver.disconnect();
            if (windowResizeListener) window.removeEventListener('resize', windowResizeListener);
        };
    }, [chatMessagesContainerRef, scrollToBottomOfChat]);

    // There's one edge case where the height of the chatMessagesContainerRef doesn't change, but
    // we still want the message to animate in, ie: the chat window only contains one single "welcome"
    // message from the bot and the user clicks "New Topic", which essentially replaces the existing text
    // with the exact same text. We can listen to the chatHistory to detect when that happens.
    useEffect(() => {
        scrollToBottomOfChat();
    }, [chatHistory, scrollToBottomOfChat]);

    return {
        chatWindowRef,
        chatMessagesContainerRef,
    };
}
