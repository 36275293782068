import { type LinkToLessonInStream } from 'Lessons';
import {
    SourceLocationType,
    type DisplayableNonLessonFrameSourceLocation,
    type SourceLocation,
    type LessonFrameSourceLocation,
    type HelpscoutArticleSourceLocation,
    type SourceLink,
    type SourceMetadata,
    type AnswerSourceFormattedForEventPayload,
} from '../TutorBot.types';

export function isHelpscoutSourceLocation(location?: SourceLocation): location is HelpscoutArticleSourceLocation {
    return !!location && location.type === SourceLocationType.HelpScoutArticle;
}

export function isLessonFrameSourceLocation(location?: SourceLocation): location is LessonFrameSourceLocation {
    return !!location && location.type === SourceLocationType.LessonFrame;
}

export function isDisplayableNonLessonFrameSourceLocation(
    location?: SourceLocation | LinkToLessonInStream,
): location is DisplayableNonLessonFrameSourceLocation {
    if (!location) return false;
    if (isLinkToLessonInStream(location)) return false;
    return !isLessonFrameSourceLocation(location);
}

export function isLinkToLessonInStream(link: LinkToLessonInStream | SourceLocation): link is LinkToLessonInStream {
    return (link as LinkToLessonInStream).lessonLaunchPath !== undefined;
}

export function getUrlForLink(link: LinkToLessonInStream | DisplayableNonLessonFrameSourceLocation) {
    if (isLinkToLessonInStream(link)) return link.lessonLaunchPath;
    if (isDisplayableNonLessonFrameSourceLocation(link)) return link.url;
    return null;
}

export function sourceLinkTitle(sourceLink: SourceLink) {
    if (isLinkToLessonInStream(sourceLink.link)) {
        return sourceLink.link.lessonTitle;
    }
    if (isDisplayableNonLessonFrameSourceLocation(sourceLink.link)) {
        return sourceLink.link.title;
    }
    throw new Error('Unexpected link type');
}

export function getSourceLocation(source: SourceMetadata): AnswerSourceFormattedForEventPayload {
    // We can track down the relevant info based on any of the entries in locations,
    // so we'll just use the first one
    const location = source.locations[0];

    return {
        lesson_id: isLessonFrameSourceLocation(location) ? location.lessonId : undefined,
        frame_id: isLessonFrameSourceLocation(location) ? location.frameId : undefined,
        helpscout_collection_id: isHelpscoutSourceLocation(location) ? location.collectionId : undefined,
        url: isDisplayableNonLessonFrameSourceLocation(location) ? location.url : undefined,
        id: source.id,
    };
}
