import { generateGuid } from 'guid';
import { type Nullable } from '@Types';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
    getActiveConversationId,
    getActiveConversationMessages,
    getErroredAiMessage,
    getRetriableMessage,
    chatActions,
    getMessageIsInActiveConversationSelector,
} from '../redux/chat';
import { useAskTutorBot } from './useAskTutorBot';
import {
    type OutgoingMessage,
    type BotClientContext,
    type OutgoingMessageInputs,
    type PretendAiMessage,
} from '../TutorBot.types';
import { HAS_SEEN_INITIAL_GREETING } from '../constants';
import { createOutgoingMessage } from '../utils/createOutgoingMessage';
import { isOutgoingMessage, isPretendAiMessage } from '../utils/ChatMessage';

export function useConversation(
    clientContext: BotClientContext,
    relevantCohortId: Nullable<string>,
    initialMessage?: OutgoingMessage | PretendAiMessage | null,
    endConversationOnClose?: boolean,
) {
    const dispatch = useDispatch();
    const { sendMessage, closeSocket, cancelCurrentStreamingMessage } = useAskTutorBot(clientContext);
    const [hasSeenInitialGreeting, setHasSeenInitialGreeting] = useLocalStorage(HAS_SEEN_INITIAL_GREETING, false);

    const activeConversationId = useSelector(getActiveConversationId);
    const chatHistory = useSelector(getActiveConversationMessages);

    const erroredAiMessage = useSelector(getErroredAiMessage);
    const retriableMessage = useSelector(getRetriableMessage);

    const startNewConversation = useCallback(() => {
        dispatch(
            chatActions.setActiveConversation({
                conversationId: generateGuid(),
                conversationMetadata: { uiContext: clientContext.uiContext },
            }),
        );
        closeSocket();
        setHasSeenInitialGreeting(true);
    }, [setHasSeenInitialGreeting, dispatch, closeSocket, clientContext.uiContext]);

    // This function has the wrong name now, but I don't know what else to call it
    // besides `sendMessage`, which would interfere with the `sendMessage` from
    // `useAskTutorBot`.
    const askUserMessage = useCallback(
        (inputs: OutgoingMessageInputs) => {
            if (!activeConversationId) {
                throw new Error('No active conversation');
            }
            if (erroredAiMessage) {
                // If we got here from `retry`, then the error message is already removed. But,
                // if the user decided to not retry the last message and instead enter a new one,
                // then we can get here when there is still a previous errored message. In that case,
                // remove the errored message so that the "retry" UI is removed from the screen.
                dispatch(chatActions.hideMessage({ messageId: erroredAiMessage.id }));
            }

            // This message will be sent on the next tick. See `send queuedOutgoingMessage`
            sendMessage({
                message: createOutgoingMessage({ inputs, conversationId: activeConversationId }),
                relevantCohortId,
            });
        },
        [erroredAiMessage, dispatch, activeConversationId, sendMessage, relevantCohortId],
    );

    const retry = useCallback(() => {
        if (!retriableMessage) {
            throw new Error('No retriable message');
        }
        if (!activeConversationId) {
            throw new Error('No active conversation');
        }
        // There are 2 cases in which a user can retry a message:
        // 1. The last message was an AI message that errored out. In this case there will be an erroredAiMessage
        // 2. The user quit out of a conversation before getting a response, reopened the conversation,
        //      and clicked to resend their last message. In this case there will be no erroredAiMessage.
        //      (We haven't actually implemented the ability to resend in this situation yet, but there is a checkox for it on https://trello.com/c/8UpltOpo)
        if (erroredAiMessage) dispatch(chatActions.hideMessage({ messageId: erroredAiMessage.id }));
        dispatch(chatActions.hideMessage({ messageId: retriableMessage.id }));
        // This message will be sent on the next tick. See `send queuedOutgoingMessage`
        sendMessage({
            message: createOutgoingMessage({
                inputs: {
                    content: retriableMessage.content,
                    role: retriableMessage.role,
                    payload: retriableMessage.payload,
                } as OutgoingMessageInputs,
                conversationId: activeConversationId,
                isRetryOfMessageId: retriableMessage.id,
            }),
            relevantCohortId,
        });
    }, [retriableMessage, erroredAiMessage, dispatch, activeConversationId, sendMessage, relevantCohortId]);

    // Open a new conversation if we don't have one, and close the active conversation when the component unmounts
    // if endConversationOnClose is true
    useEffect(() => {
        if (!activeConversationId) {
            dispatch(
                chatActions.setActiveConversation({
                    conversationId: generateGuid(),
                    conversationMetadata: { uiContext: clientContext.uiContext },
                }),
            );
        }
    }, [dispatch, closeSocket, activeConversationId, clientContext.uiContext]);

    // unmount
    useEffect(
        () => () => {
            closeSocket().then(() => {
                if (endConversationOnClose) {
                    dispatch(chatActions.reset());
                }
            });
        },
        [endConversationOnClose, closeSocket, dispatch],
    );

    const getInitialMessageIsInActiveConversationSelector = useMemo(
        () => getMessageIsInActiveConversationSelector(initialMessage?.id),
        [initialMessage],
    );
    const initialMessageIsInActiveConversation = useSelector(getInitialMessageIsInActiveConversationSelector);

    // send initial message
    useEffect(() => {
        if (initialMessageIsInActiveConversation) return;
        if (!activeConversationId) return;

        // Wait for the conversation to be initialized before trying to send the message
        if (isOutgoingMessage(initialMessage)) {
            sendMessage({
                message: initialMessage,
                relevantCohortId,
            });
        }

        if (isPretendAiMessage(initialMessage)) {
            dispatch(chatActions.addMessage({ message: initialMessage }));
        }
    }, [
        initialMessage,
        sendMessage,
        relevantCohortId,
        activeConversationId,
        chatHistory,
        initialMessageIsInActiveConversation,
        dispatch,
    ]);

    return {
        hasSeenInitialGreeting,
        retry,
        setHasSeenInitialGreeting,
        askUserMessage,
        startNewConversation,
        cancelCurrentStreamingMessage,
    };
}
