import {
    type ReviewLessonMessage,
    type ExplainScreenMessage,
    type TutorBotSystemMessageInputs,
    type OutgoingMessageInputs,
} from '../TutorBot.types';
import { createBaseMessageAttrs, isExplainScreenMessageInputs, isReviewLessonMessageInputs } from './ChatMessage';

export function createTutorBotSystemMessage({
    // see comment above OutgoingMessageInputs definition about the oddity of the `inputs` parameter
    inputs: partialInputs,
    conversationId,
    isRetryOfMessageId = null,
    isInitialMessage = false,
}: {
    inputs: { content: TutorBotSystemMessageInputs['content']; payload: TutorBotSystemMessageInputs['payload'] };
    conversationId: string;
    isRetryOfMessageId?: string | null;
    isInitialMessage?: boolean;
}) {
    const baseMessage = {
        ...createBaseMessageAttrs(),
        conversationId,
        content: '',
        complete: true as const,
        role: 'tutor_bot_system' as const,
        isRetryOfMessageId,
        hidden: true as const,
        canceled: null,
    };

    const inputs = {
        ...partialInputs,
        role: 'tutor_bot_system',
    } as OutgoingMessageInputs;

    if (isExplainScreenMessageInputs(inputs)) {
        const { content, payload } = inputs;
        const message: ExplainScreenMessage = {
            ...baseMessage,
            content,
            payload,
            isInitialMessage,
        };
        return message;
    }

    if (isReviewLessonMessageInputs(inputs)) {
        const { content, payload } = inputs;
        const message: ReviewLessonMessage = {
            ...baseMessage,
            content,
            payload,
            isInitialMessage,
        };
        return message;
    }

    throw new Error('Unsupported content for TutorBotSystemMessage');
}
