/*
    This component is tested in Message.stories.tsx

     * BotMessageWithSources
     * BotMessageWithSourcesValar
     * BotMessageWithTitlesWrapping
*/
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type LinkToLessonInStream } from 'Lessons';
import LessonLinkRow from './LessonLinkRow';
import NonLessonLinkRow from './NonLessonLinkRow';
import { isFirstLessonLinkInStream, isFirstLinkFromHelpScoutCollection } from './shared';
import { isDisplayableNonLessonFrameSourceLocation, isLinkToLessonInStream } from '../../utils/sourceLocationHelpers';
import { type DisplayableNonLessonFrameSourceLocation, type SourceLink } from '../../TutorBot.types';

type Props = { sourceLinks: SourceLink[] };

export default function MessageSources({ sourceLinks }: Props) {
    const { t } = useTranslation('back_royal');
    const {
        lessonLinks,
        nonLessonLinks,
    }: { lessonLinks: LinkToLessonInStream[]; nonLessonLinks: DisplayableNonLessonFrameSourceLocation[] } =
        useMemo(() => {
            const _lessonLinks: LinkToLessonInStream[] = [];
            const _nonLessonLinks: DisplayableNonLessonFrameSourceLocation[] = [];
            sourceLinks.forEach(sourceLink => {
                if (isLinkToLessonInStream(sourceLink.link)) {
                    _lessonLinks.push(sourceLink.link as LinkToLessonInStream);
                }
                if (isDisplayableNonLessonFrameSourceLocation(sourceLink.link)) {
                    _nonLessonLinks.push(sourceLink.link as DisplayableNonLessonFrameSourceLocation);
                }
            });
            return { lessonLinks: _lessonLinks, nonLessonLinks: _nonLessonLinks };
        }, [sourceLinks]);

    return (
        <>
            <hr className="tw-mb-3.5 tw-mt-5 tw-bg-beige-medium" />
            <div className="tw-flex tw-gap-[10px] tw-leading-4 max-sm:tw-flex-wrap">
                <div className="tw-mb-2 tw-shrink-0 tw-grow-0 tw-basis-[max-content] tw-font-medium">
                    {t('chat.chat.learn_more')}
                </div>
                <div className="tw-grid tw-grid-cols-[fit-content(60%)_auto] tw-items-start tw-gap-y-2 tw-font-semibold tw-grid-areas-[left-col_right-col] max-sm:tw-grid-cols-[auto] max-sm:tw-grid-areas-[left-col,right-col]">
                    {lessonLinks.map(lessonLink => (
                        <LessonLinkRow
                            key={lessonLink.lessonLaunchPath}
                            lessonLink={lessonLink}
                            isFirstInStream={isFirstLessonLinkInStream(lessonLink, lessonLinks)}
                            isFirstRow={sourceLinks[0].link === lessonLink}
                        />
                    ))}

                    {nonLessonLinks.map(nonLessonLink => (
                        <NonLessonLinkRow
                            key={nonLessonLink.url}
                            nonLessonLink={nonLessonLink}
                            isFirstInHelpScoutCollection={isFirstLinkFromHelpScoutCollection(
                                nonLessonLink,
                                nonLessonLinks,
                            )}
                            isFirstRow={sourceLinks[0].link === nonLessonLink}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}
