import { type MouseEventHandler, type ButtonHTMLAttributes } from 'react';
import { faWandMagicSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FlatButton } from 'FlatButton';
import { getConversationIncludesCompleteAiResponse } from '../redux/chat';

type Props = {
    className?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    isLocked: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const styleClassNames = `
    valar:tw-bg-blue quantic:tw-bg-gradient-to-r tw-from-[#FF4D63] tw-to-[#FE5F5F] tw-transition-all
    tw-py-3
    tw-pl-5 tw-pr-7 rtl:tw-pr-5 rtl:tw-pl-7
    tw-font-semibold
`;

export function ClearButton({ className, onClick, isLocked, ...rest }: Props) {
    const { t } = useTranslation('back_royal');
    const conversationIncludesCompleteAiResponse = useSelector(getConversationIncludesCompleteAiResponse);
    return (
        <FlatButton
            data-testid="new-topic-button"
            className={clsx(styleClassNames, className)}
            disabled={isLocked || !conversationIncludesCompleteAiResponse}
            onClick={onClick}
            {...rest}
        >
            <FontAwesomeIcon fontStyle="solid" icon={faWandMagicSparkles} />
            <span>{t('chat.chat.new_topic')}</span>
        </FlatButton>
    );
}
