import { Route } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom';

export const ROUTES = {
    BOT: route('bot', {}),
};

async function getLazyTutorbotComponent(component: 'BotPage') {
    const components = await import(/* webpackPrefetch: true */ './BotPage/BotPage');
    return { Component: components[component] };
}

export function getTutorBotRoutes() {
    return <Route path={ROUTES.BOT.relativePath} lazy={() => getLazyTutorbotComponent('BotPage')} />;
}
