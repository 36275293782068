import 'angular-translate';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-once';
import 'angular-moment';
import { DefaultLocale } from 'Translation';

export default angular
    .module('Translation', ['pascalprecht.translate', 'angularMoment'])

    // all currently-translated locale codes (may not be available for widespread use. see also: locale.js)
    // window.SupportedLocales is set in marketing.html.erb
    .constant('Translation.SupportedLocales', window.SupportedLocales || ['en', 'es', 'it', 'zh', 'ar', 'am'])

    // In instances where our application was bundled with English, we should
    // use window.serverDeterminedLocale when trying to figure out which locale
    // to use. If our application was bundles with a non-English locale, we should
    // give preference to the bundled locale.
    //
    // NOTE: Marketing pages only support English, and set window.SupportedLocales
    // accordingly. If someone ends up on a marketing page for an application that
    // was bundled with a non-English locale, the English locales will be loaded
    // asynchronously because the preferred locale isn't supported. This seems OK
    // since we only bundle certain CORDOVA builds (Miya Miya) with non-English locales,
    // and they can't get to marketing pages anyway.
    .constant('Translation.UseServerDeterminedLocale', DefaultLocale === 'en')

    .config([
        '$injector',
        $injector => {
            const $translateProvider = $injector.get('$translateProvider');
            const moment = $injector.get('moment');
            const SupportedLocales = $injector.get('Translation.SupportedLocales');
            const UseServerDeterminedLocale = $injector.get('Translation.UseServerDeterminedLocale');

            const preferred = UseServerDeterminedLocale ? window.serverDeterminedLocale : DefaultLocale;
            const localeToUse = SupportedLocales.includes(preferred) ? preferred : SupportedLocales[0];

            //-----------------------------
            // Configure $translateProvider
            // https://angular-translate.github.io/docs/#/guide
            $translateProvider.useLoader('translationLoader');
            $translateProvider.forceAsyncReload(true);
            $translateProvider.fallbackLanguage(DefaultLocale);

            // Set desired language based on the global 'locale' variable
            // set on the server-side using the 'Allow-Language' header. I wish we
            // could rely on the w3c variable 'navigator.language', but can be problematic
            // to rely on.
            // See http://stackoverflow.com/a/2678437/1747491
            // See http://stackoverflow.com/a/4079798/1747491
            // (We do not yet have access to Locale.preferredCode at this point, so there is a bit
            // of duplication here)
            $translateProvider.preferredLanguage(localeToUse);
            moment.updateLocale(localeToUse);

            // add support for pluralization and advanced MessageFormat syntax
            $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

            // sanitize interpolated values where possible
            $translateProvider.useSanitizeValueStrategy('escapeParameters');

            // "translate-until-keys-stabilize" functionality
            $translateProvider.useMissingTranslationHandler('missingTranslationHandler');
        },
    ]);
