import { AdmissionOfferStatus } from 'AdmissionOffer';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { type ConfigFactory } from 'FrontRoyalConfig';
import { AoiRecordType } from 'ProgramAoi';
import { isPendingProgramApplication } from 'ProgramApplication';
import { ProgramInclusionStatus } from 'ProgramInclusion';
import { getAoiRecord, getNotJoiningProgram } from 'Users';
import {
    type AiAdvisorState,
    LockedAiAdvisorState,
    UnlockedAiAdvisorState,
    InvalidAiAdvisorState,
    type BotClientContext,
} from './TutorBot.types';

export function determineAiAdvisorState(
    // FIXME: use this to determine whether to check hasBotPageAccess. Always return a state. Support the new state in useLockedAiAdvisorConfig
    uiContext: BotClientContext['uiContext'],
    $injector: ng.auto.IInjectorService,
): AiAdvisorState {
    const ConfigFactory = $injector.get<ConfigFactory>('ConfigFactory');
    if (ConfigFactory.getSync().tutorBotDisabled()) return LockedAiAdvisorState.Unavailable;

    // frameViewModel#supportsBot disables the lesson player bot in certain situations, but this function
    // always returns AdvisorUnlocked in the lesson_player context
    if (uiContext === 'lesson_player') return UnlockedAiAdvisorState.AdvisorUnlocked;
    if (uiContext === 'review_previous_material') return UnlockedAiAdvisorState.AdvisorUnlocked;

    if (uiContext === 'bot_page') return determinedBotPageState($injector);
    throw new Error(`Unsupported uiContext: ${uiContext}`);
}

function determinedBotPageState($injector: ng.auto.IInjectorService): AiAdvisorState {
    const currentUser = $injector.get<FrontRoyalRootScope>('$rootScope').currentUser;
    if (!currentUser?.hasBotPageAccess) return InvalidAiAdvisorState.NoAccess;

    const programApplication = getAoiRecord(currentUser, AoiRecordType.ProgramApplication);
    const admissionOffer = getAoiRecord(currentUser, AoiRecordType.AdmissionOffer);
    const programInclusion = getAoiRecord(currentUser, AoiRecordType.ProgramInclusion);

    const pendingApplication = !!programApplication && isPendingProgramApplication(programApplication);
    const notJoiningProgram = getNotJoiningProgram(currentUser);

    if (currentUser.canUseBotPageWithoutProgramInclusion) return UnlockedAiAdvisorState.AdvisorUnlocked;

    if (programInclusion?.status === ProgramInclusionStatus.Included) {
        if (programInclusion.contentUnlocked) {
            return UnlockedAiAdvisorState.AdvisorUnlocked;
        }

        // FIXME: We should add a specific state for these users. For now, we'll show them the
        // RegisteredNotCurrent state, even though the text is not quite right.
        // if (programInclusion.current) {
        //     return UnlockedAiAdvisorState.AdvisorContentLocked;
        // }

        return LockedAiAdvisorState.RegisteredNotCurrent;
    }

    if (programInclusion?.status === ProgramInclusionStatus.Graduated) {
        return UnlockedAiAdvisorState.AdvisorUnlocked;
    }

    if (programInclusion?.status === ProgramInclusionStatus.WillNotGraduate) {
        return LockedAiAdvisorState.ExpelledOrWithdrawn;
    }

    if (admissionOffer?.status === AdmissionOfferStatus.OfferedAdmission) {
        return LockedAiAdvisorState.OfferedAdmission;
    }

    if (notJoiningProgram) {
        return LockedAiAdvisorState.NotJoiningProgram;
    }

    if (pendingApplication && programApplication.invitedToInterview && !programApplication.interviewScheduled) {
        return LockedAiAdvisorState.AppliedInterviewPending;
    }

    if (pendingApplication) {
        return LockedAiAdvisorState.Applied;
    }

    return LockedAiAdvisorState.Signup;
}
