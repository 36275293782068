import {
    parsePhoneNumber,
    parsePhoneNumberWithError,
    getExampleNumber,
    type CountryCode,
    type ParseError,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';

// can filter in either local (for intuitive form input) or international formats (reporting)
export function formattedTelephoneNumber(number: string, countryCode?: CountryCode) {
    if (!number) {
        return number;
    }

    // Examples:
    // United States: 8646978257
    // United States: +18646978257
    // South Africa: +27119785313
    try {
        const parsedPhoneNumber = parsePhoneNumberWithError(number, countryCode || 'US');

        const countryCallingCode = `+${parsedPhoneNumber.countryCallingCode}`;

        return parsedPhoneNumber.formatInternational().replace(countryCallingCode, '').trim();
    } catch (e) {
        const error = e as ParseError;
        if (error.message === 'NOT_A_NUMBER') {
            return undefined;
        }
        return number;
    }
}

export function formattedTelephonePlaceholder(countryCode: CountryCode) {
    if (!countryCode) {
        return undefined;
    }

    const exampleNumber = getExampleNumber(countryCode, examples)?.number || '';

    return formattedTelephoneNumber(exampleNumber, countryCode);
}

export function formattedTelephoneOrText(input: string) {
    // If null or undefined input
    if (!input) {
        return input;
    }

    let parsedPhoneNumber;
    try {
        parsedPhoneNumber = parsePhoneNumber(input);
    } catch (error) {
        return input;
    }

    // If no country code is found
    if (!parsedPhoneNumber.country) {
        return input;
    }

    // If number is not valid
    if (!parsedPhoneNumber.isValid()) {
        return input;
    }

    return formattedTelephoneNumber(parsedPhoneNumber.number, parsedPhoneNumber.country);
}
