import { generateGuid } from 'guid';
import { memo, useEffect, useRef, useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions, getActiveConversationId } from '../redux/chat';
import { type BotClientContext, type PretendAiMessage } from '../TutorBot.types';
import { createPretendAiMessage } from '../utils/ChatMessage';

type Props = {
    name: string;
    examTitle: string;
    uiContext: BotClientContext['uiContext'];
};

const Component: FC<Props> = ({ name, examTitle, uiContext }) => {
    const { t } = useTranslation('back_royal');
    const hasAddedMessage = useRef(false);
    const dispatch = useDispatch();
    const activeConversationId = useSelector(getActiveConversationId);

    const getMessage = useCallback(
        (conversationId: string) => {
            const message: PretendAiMessage = createPretendAiMessage({
                content: t('chat.chat.exam_in_progress_message', { name, examTitle }),
                id: 'exam-in-progress-message',
                conversationId,
            });
            return message;
        },
        [name, examTitle, t],
    );

    useEffect(() => {
        if (hasAddedMessage.current) return;

        const newConversationId = generateGuid();
        dispatch(
            chatActions.setActiveConversation({
                conversationId: newConversationId,
                conversationMetadata: { uiContext },
            }),
        );
        dispatch(chatActions.addMessage({ message: getMessage(newConversationId!) }));
        hasAddedMessage.current = true;
        // We don't need to log this message because the conversation is disabled
    }, [dispatch, getMessage, activeConversationId, uiContext]);

    // This component is only used to add a greeting message to the chat
    return null;
};

export const InProgressExamMessage = memo(Component);
