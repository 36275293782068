import {
    type HumanMessage,
    type TutorBotSystemMessage,
    type ChatMessage,
    type StudentMessageEvaluation,
    isHumanMessage,
    isTutorBotSystemMessage,
    isRealAiMessage,
    type RealAiMessage,
} from 'TutorBot';
import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash/fp';
import { chatSlice } from './chatSlice';
import { type RootStateWithChatSlice } from './chatSlice.types';

/**
 * SELECTORS
 * The signature for selectors is `RootStateWithChatSlice => any`.
 * To make selectors more efficient, `createSelector` produces memoized selectors. You can pass in other selectors
 * as dependencies, and then the final arg is the function that derives the desired value.
 */

/* Start with a selector that selects just the state of the chat slice */
export const getChatState = (rootState: RootStateWithChatSlice) => rootState[chatSlice.name];

export const getChatMessages = createSelector(getChatState, chatState => chatState.messages);

export const getStudentEvaluations = createSelector(getChatState, chatState => chatState.studentEvaluations);

export const getOrderedChatMessages = createSelector(getChatMessages, chatMessages =>
    orderBy<ChatMessage>('createdAt')('asc')(Object.values(chatMessages)),
);

export const getActiveConversationId = createSelector(getChatState, chatState => chatState.activeConversationId);

const getConversationMessagesSelector = (conversationId: string) =>
    createSelector(getOrderedChatMessages, (chatMessages: ChatMessage[]) =>
        chatMessages.filter(chatMessage => chatMessage.conversationId === conversationId),
    );

export const getLastInitialMessage = (rootState: RootStateWithChatSlice, conversationId: string) => {
    const orderedChatMessages = getConversationMessagesSelector(conversationId)(rootState);
    return orderedChatMessages
        .slice()
        .reverse()
        .find(message => message.isInitialMessage);
};

export const getConversationIdForFrameId = (rootState: RootStateWithChatSlice, frameId: string) => {
    const conversationMetadataMap = rootState[chatSlice.name].conversationMetadataMap;
    return (
        Object.keys(conversationMetadataMap).find(conversationId => {
            const conversationMetadata = conversationMetadataMap[conversationId];
            return 'frameId' in conversationMetadata && conversationMetadata.frameId === frameId;
        }) || null
    );
};

export const getHasChangedTopic = createSelector(getChatState, chatState => chatState.hasChangedTopic);

export const getActiveConversationMessages = createSelector(
    getOrderedChatMessages,
    getActiveConversationId,
    (orderedChatMessages, activeConversationId) =>
        orderedChatMessages.filter(chatMessage => chatMessage.conversationId === activeConversationId),
);

// Only used internally, but exported so we can test it on its own
export const getConversationMetadataMap = createSelector(getChatState, chatState => chatState.conversationMetadataMap);

export const getActiveConversationUiContext = createSelector(
    getActiveConversationId,
    getConversationMetadataMap,
    (activeConversationId, conversationMetadataMap) =>
        conversationMetadataMap[activeConversationId ?? '']?.uiContext ?? null,
);

export const getConversationIncludesCompleteAiResponse = createSelector(
    getActiveConversationMessages,
    (activeConversationMessages: ChatMessage[]) =>
        activeConversationMessages.some(message => isRealAiMessage(message) && message.complete),
);

export const getActiveConversationGreetingMessages = createSelector(
    getActiveConversationMessages,
    (activeConversationMessages: ChatMessage[]) => {
        const indexOfFirstHumanMessage = activeConversationMessages.findIndex(message => message.role === 'human');
        const messagesBeforeFirstHumanMessage =
            indexOfFirstHumanMessage === -1
                ? activeConversationMessages
                : activeConversationMessages.slice(0, indexOfFirstHumanMessage);
        return messagesBeforeFirstHumanMessage.filter(message => message.role === 'ai');
    },
);

export const getMostRecentMessage = createSelector(getActiveConversationMessages, messages => messages.at(-1));

export const getErroredAiMessage = createSelector(getMostRecentMessage, message =>
    message?.role === 'ai' && message.hasError && !message.hidden ? message : null,
);

export const getAiMessageLoading = createSelector(
    getMostRecentMessage,
    message => isRealAiMessage(message) && !message.complete && !message.hidden,
);

export const getAiMessageStreaming = createSelector(
    getMostRecentMessage,
    getAiMessageLoading,
    (message, aiMessageLoading) => aiMessageLoading && !!message?.content,
);

export const getStreamingAiMessage = createSelector(
    getMostRecentMessage,
    getAiMessageStreaming,
    (message, aiMessageStreaming) => {
        if (aiMessageStreaming) return message as RealAiMessage;
        return null;
    },
);

export const getRetriableMessage = createSelector(
    getActiveConversationMessages,
    (messages): HumanMessage | TutorBotSystemMessage | null =>
        (orderBy<ChatMessage>('createdAt')('desc')(messages).find(
            message => isHumanMessage(message) || isTutorBotSystemMessage(message),
        ) as HumanMessage | TutorBotSystemMessage | undefined) || null,
);

export const getMostRecentStudentEvaluation = createSelector(getStudentEvaluations, studentEvaluations =>
    orderBy<StudentMessageEvaluation>('createdAt')('desc')(Object.values(studentEvaluations)).at(0),
);

/* Factories to produce a selector that depends on an argument
    -- Usage in a component:
    const chatMessageSelector = useMemo(() => getChatMessageSelector(id), [id]);
    const chatMessage = useSelector(chatMessageSelector);
*/
export const getStudentEvaluationForMessageSelector = (messageId: string) =>
    createSelector(getStudentEvaluations, studentEvaluations => studentEvaluations[messageId]);

export const getChatMessageSelector = (messageId: string) =>
    createSelector(getChatState, chatState => chatState.messages[messageId]);

export const getMessageIsInActiveConversationSelector = (messageId: string | undefined) =>
    createSelector(
        getActiveConversationMessages,
        (activeConversationMessages): boolean => !!activeConversationMessages.find(message => message.id === messageId),
    );

export const getMessageFromMessageId = (state: RootStateWithChatSlice, messageId: string) =>
    getChatMessages(state)[messageId];
