import { useState, useCallback, useRef, useEffect } from 'react';
import { useErrorLogService } from 'ErrorLogging';
import { generateGuid } from 'guid';
import { useEventLogger } from 'FrontRoyalAngular/useEventLogger';

/**
 * A hook to interface with a WebSocket to control when it is opened/closed,
 * send a message through the socket, and register new-message listeners. Message transformation
 * functions passed in as arguments should be memoized across renders with, for example, useCallback
 * @param {Object} props
 * @param {string} props.url the socket url, e.g. `wss://my-domain.com/chat`
 * @param {function} props.transformSocketMessage a function to transform the incoming message.data
 * @param {function} props.transformClientMessage a function to transform outgoing message data to `string | ArrayBufferLike | Blob | ArrayBufferView` before sending
 */
export function useWebSocket<
    TransformedIncomingSocketMessageType,
    OutgoingSocketMessageType,
    IncomingSocketMessageDataType,
    SendableSocketMessageDataType extends string | ArrayBufferLike | Blob | ArrayBufferView,
>({
    transformSocketMessage,
    transformClientMessage,
    handleSocketClose,
}: {
    transformSocketMessage?: (data: IncomingSocketMessageDataType) => TransformedIncomingSocketMessageType;
    transformClientMessage?: (message: OutgoingSocketMessageType) => SendableSocketMessageDataType;
    handleSocketClose?: () => void;
}) {
    const ErrorLogService = useErrorLogService();
    const EventLogger = useEventLogger();
    /* Note: I initially tried implementing this in a more React way, by storing the messages
       in a useState array (setting new values by passing a function to the setter), including that array's last value
       in the return of this hook, and handling that last value via a useEffect inside the useAskTutorBot hook.
       Confusingly, not every update to the array triggered a re-render when messages arrived near-simultaneously
       from the socket, so some messages weren't getting handled by the useEffect. Ergo listeners.
    */
    const [listeners, setListeners] = useState<((m: TransformedIncomingSocketMessageType) => void)[]>([]);
    const webSocketRef = useRef<WebSocket | null>(null);
    const closePromiseRef = useRef<Promise<void> | null>(null);
    const openPromiseRef = useRef<Promise<WebSocket> | null>(null);
    const isOpenRef = useRef<boolean>(false);
    const socketIdRef = useRef<string | null>(null);

    function reset() {
        closePromiseRef.current = null;
        openPromiseRef.current = null;
        webSocketRef.current = null;
        isOpenRef.current = false;
        socketIdRef.current = null;
    }

    const close = useCallback(() => {
        const webSocket = webSocketRef.current;
        if (!webSocket) return Promise.resolve();
        if (!closePromiseRef.current) {
            closePromiseRef.current = new Promise<void>(resolve => {
                webSocket.onclose = (_ev: CloseEvent) => {
                    reset();
                    resolve();
                };
                webSocket.close();
            });
        }
        return closePromiseRef.current;
    }, [webSocketRef]);

    const addListener = useCallback(
        (listener: (m: TransformedIncomingSocketMessageType) => void) => {
            setListeners(prev => prev.concat(listener));
        },
        [setListeners],
    );

    const removeListener = useCallback(
        (listener: (m: TransformedIncomingSocketMessageType) => void) => {
            setListeners(prev => prev.filter(l => l !== listener));
        },
        [setListeners],
    );

    const handleMessage = useRef<(ev: MessageEvent) => void | null>();

    const connect = useCallback(
        (url: string, openAttempts = 0) => {
            if (openAttempts === 0) socketIdRef.current = generateGuid();

            return new Promise<WebSocket>((resolve, reject) => {
                const webSocket = new WebSocket(url);
                webSocketRef.current = webSocket;

                /* WebSocket errors aren't very descriptive -- the error Event does not
           contain an error message or stack trace */
                webSocket.onerror = (errorEvent: Event) => {
                    if (!isOpenRef.current && openAttempts <= 2) return;

                    const error = new Error('Error connecting to websocket');
                    /* WebSocket errors aren't very descriptive -- the error Event does not
            contain an error message or stack trace */
                    const { type, timeStamp } = errorEvent;
                    ErrorLogService.notify(error, undefined, { url, errorEvent: { type, timeStamp } });
                    reset();
                    reject(errorEvent);
                };

                webSocket.onclose = () => {
                    if (!isOpenRef.current) {
                        if (openAttempts <= 2) {
                            EventLogger.log('socket_retrying_open', { socketId: socketIdRef.current });

                            resolve(connect(url, openAttempts + 1));
                            return;
                        }
                        EventLogger.log('socket_unexpected_close', { socketId: socketIdRef.current });
                        reject(new Error('Socket failed to open'));
                    } else {
                        handleSocketClose?.();
                        webSocket.close();
                    }
                    reset();
                };

                webSocket.onopen = () => {
                    isOpenRef.current = true;
                    resolve(webSocket);
                };

                webSocket.onmessage = (ev: MessageEvent) => {
                    handleMessage.current?.(ev);
                };
            });
        },
        [ErrorLogService, EventLogger, handleSocketClose],
    );

    const open = useCallback(
        async (url: string) => {
            if (!openPromiseRef.current) {
                openPromiseRef.current = connect(url);
            }

            return openPromiseRef.current;
        },
        // we track isOpen with a ref rather than with a state variable so that opening the socket does
        // not cause the `open` callback to get replaced.
        [connect],
    );

    const send = useCallback(
        (clientMessage: OutgoingSocketMessageType) => {
            const clientMessageToSocket = transformClientMessage?.(clientMessage) ?? clientMessage;
            webSocketRef.current?.send?.(clientMessageToSocket as SendableSocketMessageDataType);
        },
        [transformClientMessage],
    );

    useEffect(() => {
        handleMessage.current = (ev: MessageEvent) => {
            const message = transformSocketMessage?.(ev.data) ?? ev.data;
            listeners.forEach(l => l(message));
        };
    }, [listeners, transformSocketMessage]);

    return { open, close, send, addListener, removeListener };
}
