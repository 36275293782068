import angularModule from 'Translation/angularModule/scripts/translation_module';

angularModule.factory('missingTranslationHandler', [
    '$injector',
    $injector => (translationId, $uses) => {
        const ErrorLogService = $injector.get('ErrorLogService');

        ErrorLogService.missingTranslations = ErrorLogService.missingTranslations || {};
        const missingTranslationKey = `${$uses}.${translationId}`;

        // I saw bad things happen when I had a whole locale worth of missing translations,
        // so throttling
        if (
            !ErrorLogService.missingTranslations[missingTranslationKey] &&
            _.size(ErrorLogService.missingTranslations) < 20
        ) {
            let message;

            // When locales fail to load dynamically, we get a lot of translation missing
            // errors.  We want all those to fingerprint together (at least for now)
            if ($uses === 'en') {
                message = `Missing English translation for "${translationId}".`;
            } else {
                message = `Missing ${$uses} translation.`;
            }

            $injector.get('translationErrorLoggerUtility').logTranslationError(message, { key: translationId });
            ErrorLogService.missingTranslations[missingTranslationKey] = true;
        }

        return undefined;
    },
]);
