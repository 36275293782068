import { memo } from 'react';
import { Container } from 'Container';
import { type CurrentUserIguanaObject } from 'Users';
import { type OutgoingMessage, type BotClientContext, type PretendAiMessage } from '../TutorBot.types';
import Chat from '../Chat/Chat';

type Props = {
    currentUser: CurrentUserIguanaObject;
    clientContext: BotClientContext;

    // the initialMessage will be passed through to Chat, so we borrow the type from there
    initialMessage?: OutgoingMessage | PretendAiMessage;
};

function LessonPlayerBotComponent({ currentUser, clientContext, initialMessage }: Props): JSX.Element {
    return (
        <Container className="tw-h-full">
            <Chat
                currentUser={currentUser}
                clientContext={clientContext}
                initialMessage={initialMessage}
                disableGreetingMessages
            />
        </Container>
    );
}

export const LessonPlayerBot = memo(LessonPlayerBotComponent) as typeof LessonPlayerBotComponent;

export default LessonPlayerBot;
