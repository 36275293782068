import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import { faMemoCircleInfo } from '@fortawesome/pro-light-svg-icons/faMemoCircleInfo';
import clsx from 'clsx';
import { linkStyles } from './shared';

type Props = {
    url: string;
    title: string;
    fullWidth?: boolean;
    className?: string;
};

export default function SourceLink({ url, title, fullWidth = false, className = '' }: Props) {
    return (
        <a
            data-testid="lesson-link"
            href={url}
            target="_blank"
            rel="noreferrer"
            className={clsx(
                linkStyles,
                'tw-flex tw-items-start tw-gap-[5px]',
                fullWidth && 'tw-col-span-2',
                !fullWidth && 'tw-col-[right-col] max-sm:tw-pl-2',
                className,
            )}
        >
            {/* When a link is not "fullWidth", then it is part of a section. The arrow
            connects it with the section, which has it's own link on the left. */}
            {!fullWidth && (
                <FontAwesomeIcon
                    className="tw-inline-block tw-grow-0 tw-text-xs tw-text-tutorbot-chat rtl:-tw-scale-x-100 max-sm:tw-pe-2 sm:tw-px-2"
                    icon={faLongArrowRight}
                />
            )}

            {/* When the link is full width, it is not part of a section, so we replace the
            arrow with a generic faMemoCircleInfo */}
            {fullWidth && (
                <FontAwesomeIcon
                    className="tw-inline-block tw-grow-0 tw-px-1 tw-pl-[2px] tw-text-md tw-text-tutorbot-chat"
                    icon={faMemoCircleInfo}
                />
            )}
            {title}
        </a>
    );
}
