import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ThemeSliceState } from 'Theme/redux/theme/themeSlice.types';
import { themeSliceName } from './constants';

export const defaultThemeSliceState: ThemeSliceState = {
    bgColor: 'beige',
};

/**
 * SLICE
 */
export const themeSlice = createSlice({
    name: themeSliceName,
    initialState: defaultThemeSliceState,
    reducers: {
        setBgColor: (state: ThemeSliceState, action: PayloadAction<{ bgColor: ThemeSliceState['bgColor'] }>) => {
            state.bgColor = action.payload.bgColor;
        },
    },
});

/**
 * EXPORT REDUCER AND ACTIONS
 */
export const themeReducer = themeSlice.reducer;
export const themeActions = themeSlice.actions;
