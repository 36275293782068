// tested at Lessons/angularModule/spec/lesson/frame_list/frame/tutorBotDescription.spec.ts

import { type AnyObject } from '@Types';
import {
    type ChallengesComponent,
    type MultipleChoiceChallengeComponent,
    type InteractiveCardsComponent,
    type ChallengeOverlayBlankComponent,
    type ChallengesTutorBotDescription,
    type ChallengeDescription,
    type SelectableAnswerDescription,
    type UserInputChallengeComponent,
    type ComposeBlanksChallengeDescription,
    type ComposeBlanksChallengesComponent,
    type FillInTheBlanksChallengesComponent,
    type FillInTheBlanksChallengeDescription,
    type BlanksOnImageChallengeDescription,
    type BlanksOnImageChallengesComponent,
    type ComposeBlanksOnImageChallengesComponent,
    type HasBlank,
    type BlankLocation,
    type ComposeBlanksOnImageChallengeDescription,
    type MultipleCardMultipleChoiceChallengesComponent,
    type HasPrompt,
    type ChallengeComponent,
    type MultipleCardMultipleChoiceChallengeDescription,
    type ThisOrThatChallengeDescription,
    type ThisOrThatChallengesComponent,
    type MatchingChallengesComponent,
    type MatchingChallengeDescription,
    type BasicMultipleChoiceChallengesComponent,
    type BasicMultipleChoiceChallengeDescription,
    type FallbackFrameDescription,
} from 'Lessons';
import lessonTextFormattedForTutorbot from './getLessonTextFormattedForTutorbot';

function getInstructions(challengesComponent: ChallengesComponent): string {
    return challengesComponent.frame().miniInstructions;
}

function getMainText(challengesComponent: ChallengesComponent): string {
    const mainText = lessonTextFormattedForTutorbot(
        challengesComponent.mainTextComponent.text,
        challengesComponent.mainTextComponent.modals?.map(m => m.text) || [],
    );
    return mainText;
}

function getHasMainImage(challengesComponent: ChallengesComponent) {
    return !!(
        challengesComponent.sharedContentForFirstImage ||
        challengesComponent.sharedContentForSecondImage ||
        challengesComponent.sharedContentForInteractiveImage
    );
}

function getMultipleChoiceAnswerDescriptions(
    challenge: MultipleChoiceChallengeComponent,
): SelectableAnswerDescription[] {
    return challenge.answers.map(answer => {
        let isCorrect: boolean | null = null;
        if (!challenge.no_incorrect_answers) {
            isCorrect = challenge.correctAnswers.includes(answer);
        }
        const messageComponent = challenge.messageComponentFor(answer, 'validated');
        const baseObj = {
            id: answer.id,
            isCorrect,
            message: messageComponent?.messageText?.text || null,
        };
        const answerText = answer.text;
        const hasImage = !!answer.image;
        if (answerText) {
            return {
                ...baseObj,
                text: answerText.text,
                hasImage: false,
            };
        }
        if (hasImage) {
            return {
                ...baseObj,
                text: null,
                hasImage: true,
            };
        }
        throw new Error('Answer has neither text nor image');
    });
}

function getInteractiveCardsPrompt(
    challengesComponent:
        | BlanksOnImageChallengesComponent
        | ComposeBlanksOnImageChallengesComponent
        | MultipleCardMultipleChoiceChallengesComponent,
    challenge: ChallengeComponent,
): HasPrompt {
    const sharedContent = challengesComponent.sharedContentForInteractiveImage;
    const challengePrompt = sharedContent.cardForChallenge(challenge);
    if (challengePrompt.text) {
        return {
            promptText: challengePrompt.text.text,
            hasPromptImage: false,
        };
    }
    return {
        promptText: null,
        hasPromptImage: true,
    };
}

type ThisOrThatPromptInput = {
    promptComponents: ThisOrThatChallengesComponent['sharedContentForInteractiveImage']['tilePrompts'];
    challenge: ThisOrThatChallengesComponent['challenges'][number];
};
type MatchingPromptInput = {
    promptComponents: MatchingChallengesComponent['sharedContentForInteractiveImage']['matchingChallengeButtons'];
    challenge: MatchingChallengesComponent['challenges'][number];
};
function getPromptFromMultipleChoiceChallenge({
    promptComponents,
    challenge,
}: ThisOrThatPromptInput | MatchingPromptInput) {
    let challengePrompt:
        | ThisOrThatPromptInput['promptComponents'][number]
        | MatchingPromptInput['promptComponents'][number]
        | null = null;
    // Not sure why, but a for loop is the only thing that makes typescript happy here
    for (let i = 0; i < promptComponents.length; i++) {
        const prompt = promptComponents[i];
        if (prompt.challenge === challenge) {
            challengePrompt = prompt;
        }
    }
    if (!challengePrompt) {
        throw new Error('Could not find prompt for this or that challenge');
    }
    if (challengePrompt.text) {
        return {
            promptText: challengePrompt.text.text,
            hasPromptImage: false as const,
        };
    }

    if (challengePrompt.image) {
        return {
            promptText: null,
            hasPromptImage: true as const,
        };
    }

    throw new Error('challengePrompt has neither text nor image');
}

function getMultipleChoiceChallengeDescription(challenge: MultipleChoiceChallengeComponent) {
    return {
        id: challenge.id,
        inputType: 'multipleChoice' as const,
        answers: getMultipleChoiceAnswerDescriptions(challenge),
        noIncorrectAnswers: challenge.no_incorrect_answers || false,
        checkMany: false as const, // this is overridden where necessary
    };
}

function getUserInputChallengeDescription(challenge: UserInputChallengeComponent) {
    return {
        type: challenge.editor_template, // always 'basic_user_input'
        id: challenge.id,
        inputType: 'userInput' as const,
        correctAnswerText: challenge.correctAnswerText,
    };
}

function getComposeBlanksChallengeDescription(
    challenge: ComposeBlanksChallengesComponent['challenges'][number],
    challengesComponent: ComposeBlanksChallengesComponent,
    challengeIndex: number,
): ComposeBlanksChallengeDescription {
    return {
        ...getUserInputChallengeDescription(challenge),
        blankLocation: 'mainText' as const,
        blankText: getBlankText(challengesComponent, challengeIndex),
        hasBlankImage: false,
    };
}

function getFillInTheBlanksChallengeDescription(
    challenge: FillInTheBlanksChallengesComponent['challenges'][number],
    challengesComponent: FillInTheBlanksChallengesComponent,
    challengeIndex: number,
): FillInTheBlanksChallengeDescription {
    return {
        ...getMultipleChoiceChallengeDescription(challenge),
        type: challenge.editor_template,
        blankLocation: 'mainText' as const,
        blankText: getBlankText(challengesComponent, challengeIndex),
        hasBlankImage: false,
    };
}

function getBasicMultipleChoiceChallengeDescription(
    challenge: BasicMultipleChoiceChallengesComponent['challenges'][number],
): BasicMultipleChoiceChallengeDescription {
    return {
        ...getMultipleChoiceChallengeDescription(challenge),
        type: 'basic_multiple_choice',
        checkMany: challenge.editor_template === 'check_many',
    };
}

function getBlanksOnImageChallengeDescription(
    challenge: BlanksOnImageChallengesComponent['challenges'][number],
    challengesComponent: BlanksOnImageChallengesComponent,
): BlanksOnImageChallengeDescription {
    return {
        type: challenge.editor_template,
        ...getMultipleChoiceChallengeDescription(challenge),
        ...getInteractiveCardsPrompt(challengesComponent, challenge),
        ...getOverlayBlankInfo('mainImage' as const, challengesComponent, challenge),
    };
}

function getComposeBlanksOnImageChallengeDescription(
    challenge: ComposeBlanksOnImageChallengesComponent['challenges'][number],
    challengesComponent: ComposeBlanksOnImageChallengesComponent,
): ComposeBlanksOnImageChallengeDescription {
    return {
        ...getUserInputChallengeDescription(challenge),
        ...getInteractiveCardsPrompt(challengesComponent, challenge),
        ...getOverlayBlankInfo('mainImage' as const, challengesComponent, challenge),
    };
}

function getMultipleCardMultipleChoiceChallengeDescription(
    challenge: MultipleCardMultipleChoiceChallengesComponent['challenges'][number],
    challengesComponent: MultipleCardMultipleChoiceChallengesComponent,
): MultipleCardMultipleChoiceChallengeDescription {
    return {
        // We purposely do not use challenge.editor_template here. See comment
        // above the definition of the MultipleCardMultipleChoiceChallengeDescription type
        type: 'basic_multiple_choice',
        ...getMultipleChoiceChallengeDescription(challenge),
        ...getInteractiveCardsPrompt(challengesComponent, challenge),
    };
}

function getThisOrThatChallengeDescription(
    challenge: ThisOrThatChallengesComponent['challenges'][number],
    challengesComponent: ThisOrThatChallengesComponent,
): ThisOrThatChallengeDescription {
    return {
        type: challenge.editor_template,
        ...getMultipleChoiceChallengeDescription(challenge),
        ...getPromptFromMultipleChoiceChallenge({
            promptComponents: challengesComponent.sharedContentForInteractiveImage.tilePrompts,
            challenge,
        }),
    };
}

function getMatchingChallengeDescription(
    challenge: MatchingChallengesComponent['challenges'][number],
    challengesComponent: MatchingChallengesComponent,
): MatchingChallengeDescription {
    return {
        type: challenge.editor_template,
        ...getMultipleChoiceChallengeDescription(challenge),
        ...getPromptFromMultipleChoiceChallenge({
            promptComponents: challengesComponent.sharedContentForInteractiveImage.matchingChallengeButtons,
            challenge,
        }),
    };
}

function getOverlayBlankInfo<T extends BlankLocation>(
    blankLocation: T,
    challengesComponent: BlanksOnImageChallengesComponent | ComposeBlanksOnImageChallengesComponent,
    challenge:
        | BlanksOnImageChallengesComponent['challenges'][number]
        | ComposeBlanksOnImageChallengesComponent['challenges'][number],
): HasBlank<T> {
    const overlay = (challengesComponent.sharedContentForInteractiveImage! as InteractiveCardsComponent)
        .cardForChallenge(challenge)
        .overlayComponents.find((o: ChallengeOverlayBlankComponent) => o.challenge === challenge);
    if (overlay?.text) {
        return {
            blankLocation,
            blankText: overlay.text.text,
            hasBlankImage: false,
        };
    }
    return {
        blankLocation,
        blankText: null,
        hasBlankImage: true,
    };
}

function getBlankText(challengesComponent: ChallengesComponent, challengeIndex: number): string {
    const blankEl = $(challengesComponent.mainTextComponent.formatted_text)
        .find('cf-challenge-blank')
        .eq(challengeIndex);

    // This line removes the mathjax that has been formatted into html. There is also a <script> tag
    // that includes the original, unformatted mathjax, so that will still be shown.
    blankEl.find('.mjx-chtml').remove();
    return blankEl.text();
}

function getChallengeDescription(
    challengesComponent: ChallengesComponent,
    challengeIndex: number,
): ChallengeDescription {
    if (challengesComponent.editor_template === 'blanks_on_image') {
        const challenge = challengesComponent.challenges[challengeIndex];
        const description = getBlanksOnImageChallengeDescription(challenge, challengesComponent);
        return description;
    }

    if (challengesComponent.editor_template === 'fill_in_the_blanks') {
        const challenge = challengesComponent.challenges[challengeIndex];
        return getFillInTheBlanksChallengeDescription(challenge, challengesComponent, challengeIndex);
    }

    if (challengesComponent.editor_template === 'compose_blanks') {
        const challenge = challengesComponent.challenges[challengeIndex];
        return getComposeBlanksChallengeDescription(challenge, challengesComponent, challengeIndex);
    }

    if (challengesComponent.editor_template === 'compose_blanks_on_image') {
        const challenge = challengesComponent.challenges[challengeIndex];
        return getComposeBlanksOnImageChallengeDescription(challenge, challengesComponent);
    }

    if (challengesComponent.editor_template === 'multiple_card_multiple_choice') {
        const challenge = challengesComponent.challenges[challengeIndex];
        return getMultipleCardMultipleChoiceChallengeDescription(challenge, challengesComponent);
    }

    if (challengesComponent.editor_template === 'this_or_that') {
        const challenge = challengesComponent.challenges[challengeIndex];
        return getThisOrThatChallengeDescription(challenge, challengesComponent);
    }

    if (challengesComponent.editor_template === 'matching') {
        const challenge = challengesComponent.challenges[challengeIndex];
        return getMatchingChallengeDescription(challenge, challengesComponent);
    }

    if (
        challengesComponent.editor_template === 'basic_multiple_choice' ||
        challengesComponent.editor_template === 'image_hotspot' ||
        challengesComponent.editor_template === 'multiple_choice_poll'
    ) {
        const challenge = challengesComponent.challenges[challengeIndex];
        return getBasicMultipleChoiceChallengeDescription(challenge);
    }

    throw new Error(`Unknown challenges editor template: ${(challengesComponent as AnyObject).editor_template}`);
}

function getChallengeDescriptions(challengesComponent: ChallengesComponent): ChallengeDescription[] {
    return challengesComponent.challenges.map((_, i) => getChallengeDescription(challengesComponent, i));
}

export function describeChallengesComponentForTutorbot(
    challengesComponent: ChallengesComponent,
): ChallengesTutorBotDescription {
    return {
        id: challengesComponent.frame().id,
        type: challengesComponent.editor_template,
        mainText: getMainText(challengesComponent),
        hasMainImage: getHasMainImage(challengesComponent),
        instructions: getInstructions(challengesComponent),
        challenges: getChallengeDescriptions(challengesComponent),

        // FIXME: In order to satisfy typescript, we would  to guarantee that `challengesComponent.editor_template` agrees
        // with the type of all the challenges returned by getChallengeDescriptions.
        // Until we do that, we need to use `as` here.
    } as ChallengesTutorBotDescription;
}

export function getFallbackTutorbotDescriptionForChallengesComponent(
    challengesComponent: ChallengesComponent,
): FallbackFrameDescription {
    // Just in case something errors when we try to generate the full description, hopefully we can
    // at least return the text
    return {
        id: challengesComponent.frame().id,
        type: challengesComponent.editor_template,
        mainText: getMainText(challengesComponent),
        hasMainImage: getHasMainImage(challengesComponent),
        instructions: getInstructions(challengesComponent),
    };
}

export default describeChallengesComponentForTutorbot;
