import { useLearnerContentCache } from 'LearnerContentCache/useLearnerContentCache';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type StudentDashboardContentResponse } from 'StudentDashboard';

const ONE_MINUTE = 60 * 1000;

export function useInProgressExam() {
    const LearnerContentCache = useLearnerContentCache();
    const [dashboardContent, setDashboardContent] = useState<StudentDashboardContentResponse>();

    const inProgressExam = useMemo(() => {
        const streams = dashboardContent?.result?.flatMap(resultObj => resultObj.lesson_streams) ?? [];
        const exams = streams.filter(s => s.exam);
        return exams.find(exam => exam.lesson_streams_progress?.inProgress);
    }, [dashboardContent]);

    const getDashboardContent = useCallback(async () => {
        const response = await LearnerContentCache.ensureStudentDashboard();
        // Due to mutation via `shoveProgressIntoContent`, we must currently make a new object
        // in order to trigger an update to the `inProgressExam` memo.
        setDashboardContent({ ...response });
    }, [LearnerContentCache, setDashboardContent]);

    useEffect(() => {
        // Check on mount
        getDashboardContent();
        // Re-check roughly in step with status pings.
        const interval = setInterval(getDashboardContent, ONE_MINUTE);
        // Also re-check when tab/window takes focus
        window.addEventListener('focus', getDashboardContent);

        return () => {
            clearInterval(interval);
            window.removeEventListener('focus', getDashboardContent);
        };
    }, [getDashboardContent]);

    return { inProgressExam };
}
