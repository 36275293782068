/**
 * - `empty`: The user is in a tuition/registration situation, and we should show the tuition/registration
    header, but there is nothing we need to show in the body.
 * - `registeredManageBilling`: The user has registered and either is in a good state, needs to fix their
    payment method, or finish Stripe 3DSecure.
 * - `cohortRegistrationNoPlanSelection`: The user previously registered, still owes us money, but does not
    have a Subscription. They have no decision to make wrt. TuitionPlan, but they need to re-register to
    setup a Subscription. This can happen to users readmitted after expulsion.
 * - `cohortRegistrationFullScholarship`: The user has been offered admission with a full scholarship and needs
    to register via SelfRegister.
 * - `cohortRegistrationPlanSelection`: The user has been offered admission into one Cohort Section with a partial
    scholarship or no scholarship and needs to select a TuitionPlan and then register.
 * - `specialCase`: The user is probably in a tuition/registration situation, but we don't know how to deal
    with their current state.
 */
export enum TuitionAndRegistrationSection {
    empty = 'empty',
    registeredManageBilling = 'registeredManageBilling',
    cohortRegistrationNoPlanSelection = 'cohortRegistrationNoPlanSelection',
    cohortRegistrationFullScholarship = 'cohortRegistrationFullScholarship',
    cohortRegistrationPlanSelection = 'cohortRegistrationPlanSelection',
    specialCase = 'specialCase',
}
