import angularModule from 'TimeoutHelpers/angularModule/scripts/timeout_helpers_module';

angularModule.factory('scopeInterval', [
    '$interval',

    $interval =>
        function (scope /* , fn, delay, count, invokeApply, pass1, pass2, ... */) {
            const args = Array.prototype.slice.call(arguments, 1);

            const intervalId = $interval.apply($interval, args);
            scope.$on('$destroy', () => {
                $interval.cancel(intervalId);
            });
            return intervalId;
        },
]);
