// For info on roles, see https://python.langchain.com/en/latest/modules/models/chat/getting_started.html
// We also have our own role called "ui" that's used for "welcome" and "loading" messages.

import { type AnyObject } from '@Types';
import { type LinkToLessonInStream } from 'Lessons';
import {
    type EXPLAIN_SCREEN_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
    type REVIEW_LESSON_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
} from './constants';

export type Role = 'human' | 'ai' | 'tutor_bot_system';

export type Locale<KeyType extends string = string> = { key: KeyType; params?: AnyObject };

export type CancellationTrigger = 'by_user' | null;

// The SourceLocationType enum includes the types we know about, but we support the possibility of tutor bot adding
// new source location types that back_royal does not yet know about, so long as they have a url and a title. See
// GenericDisplayableSourceLocation and GenericUndisplayableSourceLocation below
export enum SourceLocationType {
    LessonFrame = 'lesson_frame',
    HelpScoutArticle = 'helpscout_article',
    LinkToFile = 'link_to_file',
}

export enum HelpScoutCollectionId {
    QuanticStudentHandbook = '5be30cdb2c7d3a31944dade5', // https://secure.helpscout.net/docs/5be30cdb2c7d3a31944dade5/
    QuanticApplicants = '5be30a8904286304a71bfd29', // https://secure.helpscout.net/docs/5be30a8904286304a71bfd29/
    QuanticStudentResources = '5be30d1104286304a71bfd53', // https://secure.helpscout.net/docs/5be30d1104286304a71bfd53/
    QuanticGeneral = '54ebf107e4b086c0c0968fe4', // https://secure.helpscout.net/docs/54ebf107e4b086c0c0968fe4/

    ValarStudentHandbook = '61897c8b2b380503dfe02884', // https://secure.helpscout.net/docs/61897c8b2b380503dfe02884/
    ValarApplicants = '616702cd2b380503dfdfa046', // https://secure.helpscout.net/docs/616702cd2b380503dfdfa046/
    ValarStudentResources = '61897cb92b380503dfe02887', // https://secure.helpscout.net/docs/61897cb92b380503dfe02887/
    ValarGeneral = '61897cc012c07c18afde577d', // https://secure.helpscout.net/docs/61897cc012c07c18afde577d/

    ExecEdStudentHandbook = '64d26a6087bc8b31f2cb9de0', // https://secure.helpscout.net/docs/64d26a6087bc8b31f2cb9de0/
    ExecEdApplicants = '64b97afdac752e2a94768b90', // https://secure.helpscout.net/docs/64b97afdac752e2a94768b90/
    ExecEdStudentResources = '64b97af02af2e15078a7e1a5', // https://secure.helpscout.net/docs/64b97af02af2e15078a7e1a5/
    ExecEdGeneral = '64b97b1bf7e57563daff704b', // https://secure.helpscout.net/docs/64b97b1bf7e57563daff704b/
}

export enum HelpScoutCollectionUrl {
    QuanticStudentHandbook = 'https://support.quantic.edu/collection/399-student-handbook',
    QuanticApplicants = 'https://support.quantic.edu/collection/389-applicants',
    QuanticStudentResources = 'https://support.quantic.edu/collection/405-student-resources',
    QuanticGeneral = 'https://support.quantic.edu/collection/1-general',

    ValarStudentHandbook = 'https://valar-support.quantic.edu/collection/541-student-handbook',
    ValarApplicants = 'https://valar-support.quantic.edu/collection/535-applicants',
    ValarStudentResources = 'https://valar-support.quantic.edu/collection/544-student-resources',
    ValarGeneral = 'https://valar-support.quantic.edu/collection/547-general',

    ExecEdStudentHandbook = 'https://exec-ed-support.quantic.edu/collection/1141-student-handbook',
    ExecEdApplicants = 'https://exec-ed-support.quantic.edu/collection/1109-applicants',
    ExecEdStudentResources = 'https://exec-ed-support.quantic.edu/collection/1106-student-resources',
    ExecEdGeneral = 'https://exec-ed-support.quantic.edu/collection/1112-general',
}

// When a message comes back from ChatGPT, there can be multiple sources that reference the same url. This
// type keeps track of the url and all of the sources that point to it.
export type SourceLink = {
    sourceIds: string[];
    link: DisplayableNonLessonFrameSourceLocation | LinkToLessonInStream;
};

export type HelpscoutArticleSourceLocation = {
    type: SourceLocationType.HelpScoutArticle;
    url: string;
    collectionId: HelpScoutCollectionId;
    title: string;
};

export type LessonFrameSourceLocation = {
    type: SourceLocationType.LessonFrame;
    lessonId: string;
    frameId: string;
    title: string;
};

export type LinkToFileSourceLocation = {
    type: SourceLocationType.LinkToFile;
    url: string;
    title: string;
};

// The two generic types here support the case where we add a type in tutorbot
// before adding support for it here.
// So long as we have a url and title, then we can display the location
export type GenericDisplayableSourceLocation = {
    type: string;
    url: string;
    title: string;
};

// If we don't have a url or a title, then we can't display the location
export type GenericUnDisplayableSourceLocation = {
    type: string;
};

export type DisplayableSourceLocation =
    | LessonFrameSourceLocation
    | HelpscoutArticleSourceLocation
    | LinkToFileSourceLocation
    | GenericDisplayableSourceLocation;

export type DisplayableNonLessonFrameSourceLocation =
    | HelpscoutArticleSourceLocation
    | LinkToFileSourceLocation
    | GenericDisplayableSourceLocation;

export type NonLessonFrameSourceLocation = DisplayableNonLessonFrameSourceLocation | GenericUnDisplayableSourceLocation;
export type SourceLocation = LessonFrameSourceLocation | NonLessonFrameSourceLocation;

export type SourceMetadata = {
    locations: SourceLocation[];
    id: string;
};

export type AskApiResponse = {
    answer: string;
    sources: SourceMetadata[];
    botVersion: string;
    algorithm: string;
    totalTokens: number;
    totalTokensFromCache: number;
    promptTokens: number;
    promptTokensFromCache: number;
    completionTokens: number;
    completionTokensFromCache: number;
    totalCost: number;
    totalCostFromCache: number;
};

export type AskParam = {
    body: { question: string; history: string[]; userId: string | undefined; conversationId: string };
    origin: string;
    headers: AnyObject<string>;
};

type BaseChatMessage<
    Options extends {
        supportsHasError?: boolean;
        supportsCancellation?: boolean;
        supportsIsInitialMessage?: boolean;
    } = {
        supportsHasError: false;
        supportsCancellation: false;
        supportsIsInitialMessage: false;
    },
> = {
    id: string;
    conversationId: string;
    content: string;
    complete: boolean;
    createdAt: number;
    hasError: Options['supportsHasError'] extends true ? boolean : false;
    error?: boolean;
    canceled: Options['supportsCancellation'] extends true ? CancellationTrigger : null;
    payload: AnyObject | null;
    hidden: boolean;

    // A message is an "initialMessage" not because it is necessarily the first message in a conversation,
    // but because it was automatically added to the conversation when the conversation was opened (or
    // possibly when the conversation was re-opened).
    // Messages need to be marked as "initial" because they get passed into the Chat component,
    // and we need to track whether or not they've been sent already when re-opening (See getLastInitialMessage)
    isInitialMessage: Options['supportsIsInitialMessage'] extends true ? boolean : false;
};

export type AiMessageMetadata = Omit<AskApiResponse, 'answer' | 'sources'> & {
    secondsToFirstToken?: number | null;
    secondsToCompletion?: number | null;
};

export type RealAiMessage = BaseChatMessage<{
    supportsHasError: true;
    supportsCancellation: true;
}> & {
    role: 'ai';
    sources: SourceMetadata[];
    footnoteReferences: Record<string, string>; // object mapping keys, which are source ids, to references which are strings like "source:0"
    conversationContext: ConversationContext;
    aiMessageMetadata: AiMessageMetadata;
    status: Locale<'processing_message' | 'gathering_sources' | 'finished'> | null;
    isPretend: false;
    payload: null;
};

// A PretendAiMessage is generated by the client without contacting the tutorbot service. These messages are
// persisted in the server as being part of the conversation.
export type PretendAiMessage = BaseChatMessage<{ supportsIsInitialMessage: true }> & {
    role: 'ai';
    conversationContext?: never; // It's important that this not be set to prevent us setting an incomplete context in the db
    isPretend: true; // isPretend identifies this as not being a real message from the tutor bot service
    payload: null;
};

type BaseOutgoingMessage<
    role extends 'tutor_bot_system' | 'human',
    Options extends {
        payloadType: AnyObject | null;
        supportsIsInitialMessage: boolean;
    },
> = BaseChatMessage<{
    supportsHasError: false;
    supportsCancellation: false;
    supportsIsInitialMessage: Options['supportsIsInitialMessage'];
}> & {
    role: role;
    complete: true;
    isRetryOfMessageId: string | null;
    payload: Options['payloadType'];
};

export type HumanMessage = BaseOutgoingMessage<'human', { payloadType: null; supportsIsInitialMessage: false }>;

type BaseTutorBotSystemMessage<content extends string, PayloadType extends AnyObject> = BaseOutgoingMessage<
    'tutor_bot_system',
    { payloadType: PayloadType; supportsIsInitialMessage: true }
> & {
    content: content;
    hidden: true;
};
type ActiveFramePayload = {
    activeFrameId: string;
    completedFrameIds: string[];
    remainingFrameIds: string[];
};
export type ExplainScreenMessage = BaseTutorBotSystemMessage<
    typeof EXPLAIN_SCREEN_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
    ActiveFramePayload & {
        currentChallengeIndex: string | null;
        activeFrameComplete: boolean;
    }
>;
export type ReviewLessonMessage = BaseTutorBotSystemMessage<
    typeof REVIEW_LESSON_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
    ActiveFramePayload
>;
export type TutorBotSystemMessage = ExplainScreenMessage | ReviewLessonMessage;

export type OutgoingMessage = HumanMessage | TutorBotSystemMessage;
export type ChatMessage = PretendAiMessage | RealAiMessage | HumanMessage | TutorBotSystemMessage;

export type ExplainScreenMessageInputs = {
    role: 'tutor_bot_system';
    content: typeof EXPLAIN_SCREEN_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT;
    payload: ExplainScreenMessage['payload'];
};
export type ReviewLessonMessageInputs = {
    role: 'tutor_bot_system';
    content: typeof REVIEW_LESSON_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT;
    payload: ReviewLessonMessage['payload'];
};

export type HumanMessageInputs = {
    role: 'human';
    content: string;
    payload?: null | undefined;
};
export type TutorBotSystemMessageInputs = ExplainScreenMessageInputs | ReviewLessonMessageInputs;

// It is maybe a little bit awkward how some of the arguments to functions like `createTutorBotSystemMessage` are
// grouped together into `inputs`, but it allows us to use TypeScript's type inference to make sure that our
// inputs line up with what we're expecting to get back out
export type OutgoingMessageInputs = TutorBotSystemMessageInputs | HumanMessageInputs;

type BaseIncomingSocketMessageMeta = {
    tbRunId: string;
};

type BaseIncomingSocketMessage = {
    payload: AnyObject;
    meta: BaseIncomingSocketMessageMeta;
};

export type IncomingStartSocketMessage = BaseIncomingSocketMessage & {
    eventType: 'START';
    meta: BaseIncomingSocketMessageMeta & { version: string; algorithm: string; context: ConversationContext };
};

export type IncomingResultSocketMessage = BaseIncomingSocketMessage & {
    eventType: 'RESULT';
    payload: {
        sources: SourceMetadata[];
        footnoteReferences: Record<string, string>;
        answer: string;
    };
};

export type GenericIncomingSocketMessage = BaseIncomingSocketMessage & {
    eventType: 'END' | 'ERROR' | 'TOKEN_FOR_DISPLAY' | 'STATUS_UPDATE' | 'COST' | 'CLOSE';
};

export type IncomingChatSocketMessage =
    | IncomingStartSocketMessage
    | GenericIncomingSocketMessage
    | IncomingResultSocketMessage;

export type StudentMessageEvaluation = {
    messageId: ChatMessage['id'];
    label: 'thumbs_up' | 'thumbs_down';
    createdAt: number;
};
export type BotClientContextLessonPlayer = {
    uiContext: 'lesson_player';
    lessonId: string;
    completedFrameIds: string[];
    activeFrameId: string;
    remainingFrameIds: string[];
};
export type BotClientContextReviewPreviousMaterial = {
    uiContext: 'review_previous_material';

    // Since the outline is just pulled from the lesson and passed straight through to the api,
    // there is no value to typing it out more specifically. If we ever use it in typescript, then
    // we should type it out. See the python_modules/review_previous_material_algorithm/review_previous_material_conversation_context.py
    // to see what is expected
    outline: AnyObject;
};
export type BotClientContextBotPage = {
    uiContext: 'bot_page';
};
export type BotClientContext =
    | BotClientContextLessonPlayer
    | BotClientContextBotPage
    | BotClientContextReviewPreviousMaterial;

export type BotUiContext = BotClientContext['uiContext'];

export type ChatMessageForApi = Pick<ChatMessage, 'content' | 'role' | 'payload'>;
export type ClientToSocketMessage = {
    eventType: 'ASK';
    payload: {
        clientContext: BotClientContext;
        messages: ChatMessageForApi[];
    };
};

export type ConversationContext = AnyObject;

export type MessageFormValues = {
    message: string;
};

export enum WebSocketReadyState {
    CONNECTING = 0,
    OPEN = 1,
    CLOSING = 2,
    CLOSED = 3,
}

// FIXME: we should set up a discriminated union here with specific definitions
// of what might be in here
export type AnswerSourceFormattedForEventPayload = {
    lesson_id?: string;
    frame_id?: string;
    helpscout_collection_id?: string;
    url?: string;
    id?: string;
};

export type BaseMessageSentEventPayload = {
    label: Role;
    tutor_bot_conversation_id: string;
    ui_context: BotUiContext;
    sender_role: string;
    message: string;
    tutor_bot_message_id: string;
    tutor_bot_message_payload: ChatMessage['payload'];
};

export type RealAiMessageSentEventPayload = BaseMessageSentEventPayload & {
    label: 'ai';
    sender_role: 'ai';
    ai_answer_sources: AnswerSourceFormattedForEventPayload[];
    bot_version: string;
    conversation_context: ConversationContext;
    algorithm: string;
    total_tokens: number;
    total_tokens_from_cache: number;
    total_cost: number;
    total_cost_from_cache: number;
    prompt_tokens: number;
    prompt_tokens_from_cache: number;
    completion_tokens: number;
    completion_tokens_from_cache: number;
    canceled: CancellationTrigger;
    seconds_to_first_token?: number | null;
    seconds_to_completion?: number | null;
    hasError?: boolean;
};

// This state is "invalid" because you should not even be in the
// conversation UI in this case
export enum InvalidAiAdvisorState {
    NoAccess = 'NoAccess',
}

export enum LockedAiAdvisorState {
    Signup = 'Signup',
    AppliedInterviewPending = 'AppliedInterviewPending',
    Applied = 'Applied',
    ExpelledOrWithdrawn = 'ExpelledOrWithdrawn',
    NotJoiningProgram = 'NotJoiningProgram',
    OfferedAdmission = 'OfferedAdmission',
    RegisteredNotCurrent = 'RegisteredNotCurrent',
    Unavailable = 'Unavailable',
}

export enum UnlockedAiAdvisorState {
    AdvisorUnlocked = 'AdvisorUnlocked',
}

export type AiAdvisorState = InvalidAiAdvisorState | LockedAiAdvisorState | UnlockedAiAdvisorState;

export type LockedAiAdvisorConfig = {
    stateKey: LockedAiAdvisorState;
    welcomeModalText: Locale;
    welcomeModalCta?: {
        message: { headline: Locale; subtext?: Locale };
        buttonText: Locale;
        onClick: () => void;
    };
    greetingMessages: Locale[];
};

export type BaseConversationMetadata = {
    uiContext: BotUiContext;
};

export type LessonPlayerConversationMetadata = BaseConversationMetadata & {
    frameId: string;
};

export type DefaultConversationMetadata = BaseConversationMetadata;
export type ConversationMetadata = DefaultConversationMetadata | LessonPlayerConversationMetadata;
