/* eslint-disable max-classes-per-file */
import i18n from 'i18next';
import { type AnyObject } from '@Types';
// This class is used in the case where you are testing a function that takes
// either injector or TranslationHelper as an argument. In those cases,
// you will not have the higher level setup from either angular or react to handle localizations.

/*
    Usage:

    You can just get the a TranslationHelper class with `import { TranslationHelper } from 'Translation'`

    If you want to ensure that an instance of TranslationHelper is instantiated with a specific namespace,
    do something like the following. If someone instantiates a TranslationHelper with a namespace other than
    'some-namespace', there will be an error.

        import mockInjector from 'Injector/mockInjector';
        import { expectTranslationHelper } from 'Translation';

        injector = mockInjector({
            TranslationHelper: expectTranslationHelper('some.namespace'),
        });

    If you want to actually set up locales for testing the correct values, you can do the following.
    import setSpecLocales from 'Translation/setSpecLocales';
    import someLocaleJSON from 'path/to/json/locales.json';

    setSpecLocales(studentDashboardLocales);

    And then as long as you have mocked out your injector or whatever is using TranslationHelper correctly
    the get method will return the correct value from that locale JSON. If you fail to (or don't care to)
    use setSpecLocale the get method will return `localized:valuePassedToGetMethod`
*/

i18n.init({
    fallbackLng: 'en',
    defaultNS: 'back_royal',
    ns: 'back_royal',
});

export default class MockTranslationHelper {
    static expectedNamespace: string | null = null;
    namespace!: string;

    constructor(namespace: string) {
        i18n.addResourceBundle('en', 'back_royal', window?.Smartly?.locales?.modules?.en, true, true);
        const expectedNamespace = (this.constructor as typeof MockTranslationHelper).expectedNamespace;
        if (expectedNamespace && namespace !== expectedNamespace) {
            throw new Error(
                `Instantiated TranslationHelper with '${namespace}' but was expected to be instantiated with '${expectedNamespace}'`,
            );
        }
        this.namespace = namespace;
    }

    get(key: string, options: AnyObject = {}) {
        if (i18n.exists(`${this.namespace}.${key}`))
            return i18n.t(`${this.namespace}.${key}`, options as Parameters<typeof i18n.t>[1]);

        return `localized:${key}`;
    }
}

export function expectTranslationHelper(namespace: string) {
    const klass = class extends MockTranslationHelper {};
    klass.expectedNamespace = namespace;
    return klass;
}
