import angularModule from 'TimeoutHelpers/angularModule/scripts/timeout_helpers_module';

angularModule.factory('resolvableTimeout', [
    '$timeout',

    $timeout => {
        function resolvableTimeout(fn, delay, invokeApply) {
            const promise = $timeout(fn, delay, invokeApply);
            promise.fn = fn;
            return promise;
        }

        resolvableTimeout.resolve = promise => {
            // cancel returns true only if the callback has not yet
            // been triggered.  In that case, trigger it now.
            if (resolvableTimeout.cancel(promise)) {
                promise.fn();
                // resolvableTimeout.flush(); // NOTE: this seems to be required as of ~1.5.4
            }
        };

        resolvableTimeout.cancel = $timeout.cancel;
        resolvableTimeout.flush = $timeout.flush;

        return resolvableTimeout;
    },
]);
