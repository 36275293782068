import { useMemo } from 'react';
import { useSyncConfig } from 'FrontRoyalConfig';
import { regionAwareImageUrlForFormat } from 'regionAwareImage';
import { type LinkToLessonInStream } from 'Lessons';
import clsx from 'clsx';
import { linkStyles } from './shared';

type Props = { lessonLink: LinkToLessonInStream };

export default function StreamLink({ lessonLink }: Props) {
    const config = useSyncConfig();
    const streamImage = useMemo(() => {
        if (!lessonLink.streamImage) return null;

        return regionAwareImageUrlForFormat(lessonLink.streamImage, '50X50', config);
    }, [lessonLink, config]);

    return (
        <div className="tw-relative tw-flex tw-items-start">
            {streamImage && (
                <img
                    className="tw-absolute -tw-top-1 tw-left-0 tw-max-w-[25px] rtl:tw-left-auto rtl:tw-right-0 max-xs:tw-top-0"
                    src={streamImage}
                    alt={lessonLink.streamTitle}
                />
            )}
            <a
                href={lessonLink.streamDashboardPath}
                data-testid="stream-link"
                target="_blank"
                rel="noreferrer"
                className={clsx(linkStyles, streamImage && 'tw-ps-8')}
            >
                {lessonLink.streamTitle}
            </a>
        </div>
    );
}
