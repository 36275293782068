import angularModule from 'TextToImage/angularModule/scripts/text_to_image_module';

angularModule.factory('TextToImageHelper', [
    '$injector',

    $injector => {
        const SuperModel = $injector.get('SuperModel');

        return SuperModel.subclass(() => ({
            // https://stackoverflow.com/a/15666143/1747491
            _createHiDPICanvas(w, h, ratio) {
                const canvas = document.createElement('canvas');
                canvas.width = w * ratio;
                canvas.height = h * ratio;
                canvas.style.width = `${w}px`;
                canvas.style.height = `${h}px`;
                canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);
                return canvas;
            },

            // https://stackoverflow.com/a/16599668/1747491
            _getLines(ctx, text, maxWidth) {
                const words = text.split(' ');
                const lines = [];
                let currentLine = words[0];

                for (let i = 1; i < words.length; i++) {
                    const word = words[i];
                    const width = ctx.measureText(`${currentLine} ${word}`).width;
                    if (width < maxWidth) {
                        currentLine += ` ${word}`;
                    } else {
                        lines.push(currentLine);
                        currentLine = word;
                    }
                }
                lines.push(currentLine);
                return lines;
            },

            // Because this is specifically checking for ASCII chars within the range, I am
            // ignoring the eslint rule here.
            /* eslint-disable no-control-regex */
            // https://stackoverflow.com/a/14313213/1747491
            isASCII(str, extended) {
                extended = angular.isDefined(extended) ? extended : true;
                return (extended ? /^[\x00-\xFF]*$/ : /^[\x00-\x7F]*$/).test(str);
            },

            // https://stackoverflow.com/a/20552063/1957428
            getSingleLineImageText(text, options) {
                const self = this;

                const canvas = self._createHiDPICanvas(options.width, options.fontSize * 2, 8); // prevents blurry text
                const ctx = canvas.getContext('2d');

                ctx.imageSmoothingEnabled = false;
                ctx.textBaseline = 'top';
                ctx.fillStyle = options.fillColor;
                ctx.font = `${options.fontSize}px ${options.font}`;

                let width = ctx.measureText(text).width;
                let fontSize = options.fontSize;

                while (width > options.width) {
                    fontSize -= 1;

                    if (fontSize < 1) {
                        throw new Error('Could not resize text for the given width');
                    }

                    ctx.font = `${fontSize}px ${options.font}`;
                    width = ctx.measureText(text).width;
                }

                ctx.fillText(text, 0, 0, options.width);

                return canvas.toDataURL('image/png');
            },

            addImageTextToPdf(pdfDoc, text, x, y, options) {
                const self = this;

                options = options || {};
                options.height = options.height || 200; // 200 is an arbitrary value I chose
                options.lineHeight = options.lineHeight || options.fontSize;

                const canvas = self._createHiDPICanvas(options.width, options.height, 8); // prevents blurry text
                const ctx = canvas.getContext('2d');

                ctx.imageSmoothingEnabled = false;
                ctx.textBaseline = 'top';
                ctx.fillStyle = options.fillColor;
                ctx.font = `${options.fontSize}px ${options.font}`;

                // Allow the user to manually break text
                let lines = text.split('\n');

                // Also compute the automatic breaks
                lines = _.chain(lines)
                    .map(line => self._getLines(ctx, line, options.width))
                    .flattenDeep()
                    .value();

                _.forEach(lines, (line, index) => {
                    ctx.fillText(line, 0, index * options.lineHeight, options.width);
                });

                pdfDoc.image(canvas.toDataURL('image/png'), x, y, {
                    width: options.width,
                    height: options.height,
                });
            },
        }));
    },
]);
