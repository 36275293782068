import { generateGuid } from 'guid';
import { memo, useEffect, useMemo, useRef, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions, getActiveConversationGreetingMessages, getActiveConversationId } from 'TutorBot/redux/chat';
import { createPretendAiMessage } from 'TutorBot/utils/ChatMessage';
import { type PretendAiMessage } from '../TutorBot.types';

const greetingKeys = [
    'helloAgain',
    'goodDay',
    'welcomeBack',
    'dayOfWeek',
    'welcomeBackName',
    'hello',
    'hi',
    'greetingsName',
    'hiThere',
    'helloHuman',
    'morning',
    'afternoon',
    'evening',
    'salutations',
    'niceToSeeYou',
    'helloAndNiceToSeeYou',
    'niceToSeeYouName',
    'greetingsAndHappyDay',
    'greatToHaveYou',
    'heyThere',
] as const;

const promptKeys = [
    'doYouHave',
    'whatCanI',
    'whatQuery',
    'whatTopic',
    'anyQuestions',
    'whatSubjectMatter',
    'whatSubject',
    'whatTopicRequires',
    'howMayI',
    'anythingICanHelp',
    'whatQuestion',
    'howCanI',
    'whatInformation',
    'areaOfInterest',
    'whatChallenges',
    'whichConcept',
    'whatsOnYourMind',
] as const;

type Props = {
    name: string;
};

const localePath = 'greetingMessage.greetingMessage';

const filterGreetingsByTime = () => {
    const hours = new Date().getHours();
    if (hours >= 3 && hours < 11) return greetingKeys.filter(key => !key.match(/afternoon|evening/));
    if (hours >= 12 && hours < 15) return greetingKeys.filter(key => !key.match(/morning|evening/));
    return greetingKeys.filter(key => !key.match(/morning|afternoon/));
};

const Component: FC<Props> = ({ name }) => {
    const { t } = useTranslation('back_royal');
    const hasAddedMessage = useRef(false);
    const dispatch = useDispatch();
    const activeConversationId = useSelector(getActiveConversationId);
    if (!activeConversationId) throw new Error('No active conversation ID');
    const greetingMessages = useSelector(getActiveConversationGreetingMessages);

    const message = useMemo<PretendAiMessage>(() => {
        const filteredGreetings = filterGreetingsByTime();

        const greetingKeyIndex = Math.floor(Math.random() * (filteredGreetings.length - 1));
        const promptKeyIndex = Math.floor(Math.random() * (promptKeys.length - 1));
        const dayOfWeek = t(`${localePath}.${new Date().getDay()}`);

        const greeting = t(`${localePath}.${filteredGreetings[greetingKeyIndex]}`, { name, dayOfWeek });
        const prompt = t(`${localePath}.${promptKeys[promptKeyIndex]}`);

        return createPretendAiMessage({
            id: generateGuid(),
            content: `${greeting} ${prompt}`,
            conversationId: activeConversationId,
        });
    }, [activeConversationId, name, t]);

    useEffect(() => {
        if (hasAddedMessage.current || greetingMessages.length) return;

        dispatch(chatActions.addMessage({ message }));

        hasAddedMessage.current = true;
    }, [dispatch, message, greetingMessages]);

    // This component is only used to add a greeting message to the chat
    return null;
};

export const SubsequentGreetingMessage = memo(Component);
