import angularModule from 'Translation/angularModule/scripts/translation_module';

angularModule.factory('TranslationHelper', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const $translate = $injector.get('$translate');
        const $sce = $injector.get('$sce');

        return SuperModel.subclass(() => ({
            initialize(namespace) {
                this.namespace = namespace;
            },

            get(key, params, sanitizeStrategy, interpolationId, forceLanguage) {
                const fullKey = [this.namespace, key].join('.');

                // Yes, it is stupid that we use a different order of arguments
                // than translate.instant uses, but it came to be that way over
                // time and easier to just go with it then to grep for all uses
                // of this method now
                return $translate.instant(...[fullKey, params, interpolationId, forceLanguage, sanitizeStrategy]);
            },

            // If there is a situation where you sometimes expect a dynamic key to be missing a certain locale definition
            // and don't wish to trigger the missingTranslationHandler, use this method.
            getWithQuietFailure(key, params, sanitizeStrategy, interpolationId, forceLanguage) {
                const fullKey = [this.namespace, key].join('.');
                const translations = $translate.getTranslationTable();
                // Check if the key exists in the active translation table
                const keyExists = !!translations[fullKey];
                return keyExists ? this.get(key, params, sanitizeStrategy, interpolationId, forceLanguage) : undefined;
            },

            getWithPromise(key, params, sanitizeStrategy, interpolationId, defaultTranslationText, forceLanguage) {
                const fullKey = [this.namespace, key].join('.');

                return $translate(
                    ...[fullKey, params, interpolationId, defaultTranslationText, forceLanguage, sanitizeStrategy],
                ).then(translation => translation);
            },

            /*
            This method allows you to say something like "translate this key, but if
            you don't find it, then use this other key instead.  If that happens, then
            notify Sentry so we know something is missing."

            This can be useful when using dynamic keys. If you are building your key with
            something like 'some_key_prefix_'+someVariable, then you might run into something
            in prod that you never hit in development.  This method lets you handle that
            gracefully.
            */
            getWithFallbackKey(
                key,
                fallbackKey,
                params,
                fallbackParams,
                sanitizeStrategy,
                fallbackSanitizeStrategy,
                notifyIfMissing = true,
            ) {
                fallbackSanitizeStrategy = sanitizeStrategy || fallbackSanitizeStrategy;
                let val;

                if (!notifyIfMissing) {
                    const preferredVal = this.getWithQuietFailure(key, params, sanitizeStrategy);
                    const fallbackVal = this.getWithQuietFailure(fallbackKey, fallbackParams, fallbackSanitizeStrategy);
                    return preferredVal || fallbackVal;
                }

                try {
                    val = this.get(key, params, sanitizeStrategy);
                } catch (err) {
                    if (!err.toString().match('Missing translation')) {
                        throw err;
                    }
                }

                const fullKey = [this.namespace, key].join('.');
                // if the translation was not found, then this.get will return
                // the key that was passed in
                if (!val || val === fullKey) {
                    $injector
                        .get('ErrorLogService')
                        .notifyInProd(`Missing translation "${key}".  Using provided fallback.`, undefined, {
                            level: 'warning',
                        });
                    val = this.get(fallbackKey, fallbackParams, fallbackSanitizeStrategy);
                }

                return val;
            },

            htmlSafe(key, params) {
                const str = this.get(key, params);
                return $sce.trustAsHtml(str);
            },

            getCurrentLanguage() {
                return $translate.use(false);
            },
        }));
    },
]);
