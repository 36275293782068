import { HelpScoutCollectionId } from 'TutorBot';

export const helpScoutCollectionTitles = {
    'Student Handbook': [
        HelpScoutCollectionId.QuanticStudentHandbook,
        HelpScoutCollectionId.ValarStudentHandbook,
        HelpScoutCollectionId.ExecEdStudentHandbook,
    ],
    'Student Resources': [
        HelpScoutCollectionId.QuanticStudentResources,
        HelpScoutCollectionId.ValarStudentResources,
        HelpScoutCollectionId.ExecEdStudentResources,
    ],
    General: [
        HelpScoutCollectionId.QuanticGeneral,
        HelpScoutCollectionId.ValarGeneral,
        HelpScoutCollectionId.ExecEdGeneral,
    ],
    Applicants: [
        HelpScoutCollectionId.QuanticApplicants,
        HelpScoutCollectionId.ValarApplicants,
        HelpScoutCollectionId.ExecEdApplicants,
    ],
};

export function helpScoutCollectionTitle(collectionId: HelpScoutCollectionId) {
    let title = '';

    Object.entries(helpScoutCollectionTitles).forEach(([helpscoutCollectionTitle, includedCollectionIds]) => {
        if (includedCollectionIds.includes(collectionId)) {
            title = helpscoutCollectionTitle;
        }
    });

    return title;
}
