import { useAngularContext } from 'AngularContext';
import { type FrontRoyalRootScope, useTargetBrandConfig } from 'FrontRoyalAngular';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { useMemo } from 'react';
import { useDateHelper } from 'FrontRoyalAngular/useDateHelper';
import { type BaseUser, getAoiRecord, getCohort } from 'Users';
import { AoiRecordType } from 'ProgramAoi';
import { getRegistrationDeadline, getStartDate } from 'Cohorts';
import { determineAiAdvisorState } from './determineAiAdvisorState';
import { determineAiAdvisorLocked } from './determineAiAdvisorLocked';
import { type BotClientContext, LockedAiAdvisorState, type LockedAiAdvisorConfig } from './TutorBot.types';

export function useLockedAiAdvisorConfig(uiContext: BotClientContext['uiContext']) {
    const $injector = useAngularContext();
    const navigationHelper = useNavigationHelper();
    const brandConfig = useTargetBrandConfig();
    const dateHelper = useDateHelper();

    const currentUser = $injector.get<FrontRoyalRootScope>('$rootScope').currentUser;
    const cohort = getCohort(currentUser as BaseUser);
    const aiAdvisorState = useMemo(() => determineAiAdvisorState(uiContext, $injector), [uiContext, $injector]);
    const isLockedState = determineAiAdvisorLocked(aiAdvisorState);

    if (!isLockedState) return { isLocked: false, config: null };

    if (!cohort || !currentUser) return { isLocked: true, config: null };

    const institutionName = brandConfig?.brandNameShort;
    const name = currentUser.nickname || currentUser.name;
    const programApplication = getAoiRecord(currentUser!, AoiRecordType.ProgramApplication);
    const startDate = dateHelper.formattedUserFacingMonthDayLong(getStartDate(cohort), false);
    const registrationDeadline = dateHelper.formattedUserFacingMonthDayLong(getRegistrationDeadline(cohort), false);
    const applicationDeadline = dateHelper.formattedUserFacingMonthDayLong(
        currentUser?.relevantCohort?.applicationDeadline?.() as Date,
        true,
    );

    const configs: Record<LockedAiAdvisorState, LockedAiAdvisorConfig> = {
        Signup: {
            stateKey: LockedAiAdvisorState.Signup,
            welcomeModalText: { key: 'chat.chat.locked_welcome_modal', params: { institutionName } },
            welcomeModalCta: {
                message: {
                    headline: { key: 'chat.chat.locked_welcome_modal_cta_message_signup_headline' },
                    subtext: {
                        key: 'chat.chat.locked_welcome_modal_cta_message_signup_subtext',
                        params: { applicationDeadline },
                    },
                },
                buttonText: { key: 'chat.chat.locked_welcome_modal_cta_button_text_signup' },
                onClick: () => navigationHelper.loadRoute('/settings/application'),
            },
            greetingMessages: [
                { key: 'greetingMessage.greetingMessage.myMission', params: { name, institutionName } },
                { key: 'greetingMessage.greetingMessage.hopeYouWillApply' },
            ],
        },
        Applied: {
            stateKey: LockedAiAdvisorState.Applied,
            welcomeModalText: { key: 'chat.chat.locked_welcome_modal', params: { institutionName } },
            greetingMessages: [
                { key: 'greetingMessage.greetingMessage.myMission', params: { name, institutionName } },
                { key: 'greetingMessage.greetingMessage.haveWonderfulDay' },
            ],
        },
        AppliedInterviewPending: {
            stateKey: LockedAiAdvisorState.AppliedInterviewPending,
            welcomeModalText: { key: 'chat.chat.locked_welcome_modal', params: { institutionName } },
            welcomeModalCta: {
                message: {
                    headline: { key: 'chat.chat.locked_welcome_modal_cta_message_applied_interview_pending_headline' },
                },
                buttonText: { key: 'chat.chat.locked_welcome_modal_cta_button_text_applied_interview_pending' },
                onClick: () => navigationHelper.loadUrl(programApplication!.invitedToInterviewUrl),
            },
            greetingMessages: [
                { key: 'greetingMessage.greetingMessage.myMission', params: { name, institutionName } },
                { key: 'greetingMessage.greetingMessage.completeInterview' },
            ],
        },
        NotJoiningProgram: {
            stateKey: LockedAiAdvisorState.NotJoiningProgram,
            welcomeModalText: { key: 'chat.chat.locked_welcome_modal', params: { institutionName } },
            greetingMessages: [
                { key: 'greetingMessage.greetingMessage.myMission', params: { name, institutionName } },
                { key: 'greetingMessage.greetingMessage.haveWonderfulDay' },
            ],
        },
        ExpelledOrWithdrawn: {
            stateKey: LockedAiAdvisorState.ExpelledOrWithdrawn,
            welcomeModalText: { key: 'chat.chat.locked_welcome_modal', params: { institutionName } },
            greetingMessages: [
                { key: 'greetingMessage.greetingMessage.myMission', params: { name, institutionName } },
                { key: 'greetingMessage.greetingMessage.haveWonderfulDay' },
            ],
        },
        OfferedAdmission: {
            stateKey: LockedAiAdvisorState.OfferedAdmission,
            welcomeModalText: { key: 'chat.chat.locked_welcome_modal', params: { institutionName } },
            welcomeModalCta: {
                message: {
                    headline: {
                        key: 'chat.chat.locked_welcome_modal_cta_message_offered_admission_headline',
                    },
                    subtext: {
                        key: 'chat.chat.locked_welcome_modal_cta_message_offered_admission_subtext',
                        params: { registrationDeadline },
                    },
                },
                buttonText: { key: 'chat.chat.locked_welcome_modal_cta_button_text_offered_admission' },
                onClick: () => navigationHelper.loadRoute('/settings/application_status'),
            },
            greetingMessages: [
                { key: 'greetingMessage.greetingMessage.myMission', params: { name, institutionName } },
                { key: 'greetingMessage.greetingMessage.hopeYouWillJoin' },
            ],
        },
        RegisteredNotCurrent: {
            stateKey: LockedAiAdvisorState.RegisteredNotCurrent,
            welcomeModalText: { key: 'chat.chat.locked_welcome_modal', params: { institutionName } },
            greetingMessages: [
                {
                    key: 'greetingMessage.greetingMessage.congratulationsOnAcceptance',
                    params: { institutionName, name, startDate },
                },
                { key: 'greetingMessage.greetingMessage.seeYouSoon' },
            ],
        },
        Unavailable: {
            stateKey: LockedAiAdvisorState.Unavailable,
            welcomeModalText: { key: 'chat.chat.locked_unavailable' },
            greetingMessages: [],
        },
    };

    return {
        config: configs[aiAdvisorState as LockedAiAdvisorState],
        isLocked: true,
    };
}
