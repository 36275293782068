import angularModule from 'Translation/angularModule/scripts/translation_module';
import { DefaultLocale } from 'Translation';
import getFilePathsFromManifest from 'WebpackManifestHelper';

angularModule.factory('translationLoader', [
    '$injector',

    $injector => {
        const $q = $injector.get('$q');
        const $http = $injector.get('$http');
        const HttpQueue = $injector.get('HttpQueue');

        const asyncLoadedLanguagesPromises = {};

        return options => {
            if (options.key === DefaultLocale) {
                // If options.key corresponds to the DefaultLocale, i.e. the locale
                // that was bundled in the JS via localeLoader, we can assume the data
                // is on the window.
                return $q.resolve(window.Smartly.locales.modules[options.key].back_royal);
            }

            if (!asyncLoadedLanguagesPromises[options.key]) {
                const promises = [];

                getFilePathsFromManifest(`locales-${options.key}`, 'json').forEach(path => {
                    const requestConfig = {
                        method: 'GET',
                        httpQueueOptions: {
                            shouldQueue: true,
                        },
                        url: path,
                    };

                    promises.push(
                        $http(requestConfig)
                            .then(response => response.data)
                            .catch(response => {
                                HttpQueue.unfreezeAfterError(response.config);

                                $injector
                                    .get('translationErrorLoggerUtility')
                                    .logTranslationError(`Error fetching translation tables`, {
                                        response: response && response.message,
                                        attemptingToLoadLocale: options.key,
                                    });

                                throw response; // re-throw to support Locale handling
                            }),
                    );
                });

                asyncLoadedLanguagesPromises[options.key] = promises;
            }

            return Promise.all(asyncLoadedLanguagesPromises[options.key]);
        };
    },
]);
