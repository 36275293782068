import { generateGuid } from 'guid';
import {
    EXPLAIN_SCREEN_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
    REVIEW_LESSON_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT,
} from 'TutorBot/constants';
import {
    type RealAiMessage,
    type PretendAiMessage,
    type ChatMessage,
    type HumanMessage,
    type TutorBotSystemMessage,
    type ExplainScreenMessageInputs,
    type HumanMessageInputs,
    type OutgoingMessageInputs,
    type TutorBotSystemMessageInputs,
} from '../TutorBot.types';

export function isHumanMessage(message: ChatMessage | null | undefined): message is HumanMessage {
    if (!message) return false;
    return (message as HumanMessage).role === 'human';
}

export function isTutorBotSystemMessage(message: ChatMessage | null | undefined): message is HumanMessage {
    if (!message) return false;
    return (message as TutorBotSystemMessage).role === 'tutor_bot_system';
}

export function isRealAiMessage(message: ChatMessage | null | undefined): message is RealAiMessage {
    if (!message) return false;
    const aiMessage = message as RealAiMessage;
    return aiMessage.role === 'ai' && !aiMessage.isPretend;
}

export function isPretendAiMessage(message: ChatMessage | null | undefined): message is PretendAiMessage {
    if (!message) return false;
    const pretendAiMessage = message as PretendAiMessage;
    return pretendAiMessage.role === 'ai' && pretendAiMessage.isPretend;
}

export function isAiMessage(message: ChatMessage | null | undefined): message is RealAiMessage | PretendAiMessage {
    if (!message) return false;
    return isRealAiMessage(message) || isPretendAiMessage(message);
}

export function isOutgoingMessage(
    message: ChatMessage | null | undefined,
): message is HumanMessage | TutorBotSystemMessage {
    return isHumanMessage(message) || isTutorBotSystemMessage(message);
}

export function getMessageSources(message: ChatMessage): RealAiMessage['sources'] {
    return isRealAiMessage(message) ? message.sources : ([] as RealAiMessage['sources']);
}

export function createRealAiMessage({
    conversationId,
}: {
    conversationId: RealAiMessage['conversationId'];
}): RealAiMessage {
    /* Add the skeleton AI response message to the store, which
               we will update when the response data streams in */
    return {
        ...createBaseMessageAttrs(),
        role: 'ai',
        content: '',
        conversationId,
        hasError: false,
        complete: false,
        sources: [],
        footnoteReferences: {},
        isPretend: false,
        status: null,
        payload: null,
        aiMessageMetadata: {
            botVersion: '0.0.0',
            algorithm: 'bot_page',
            totalTokens: 0,
            totalTokensFromCache: 0,
            totalCost: 0.0,
            totalCostFromCache: 0.0,
            promptTokens: 0,
            promptTokensFromCache: 0,
            completionTokens: 0,
            completionTokensFromCache: 0,
        },
        conversationContext: {},
        canceled: null,
        isInitialMessage: false,
    };
}

export function createPretendAiMessage({
    content,
    id,
    conversationId,
    isInitialMessage = false,
}: {
    content: PretendAiMessage['content'];
    id?: PretendAiMessage['id'];
    conversationId: PretendAiMessage['conversationId'];
    isInitialMessage?: boolean;
}): PretendAiMessage {
    id = id ?? generateGuid();
    return {
        ...createBaseMessageAttrs(),
        role: 'ai',
        content,
        id,
        isPretend: true,
        complete: true,
        conversationId,
        canceled: null,
        isInitialMessage,
    };
}

export function createHumanMessage({
    content,
    conversationId,
    isRetryOfMessageId = null,
}: {
    content: HumanMessage['content'];
    conversationId: HumanMessage['conversationId'];
    isRetryOfMessageId?: HumanMessage['isRetryOfMessageId'];
}): HumanMessage {
    return {
        ...createBaseMessageAttrs(),
        role: 'human',
        content,
        conversationId,
        complete: true,
        isRetryOfMessageId,
        payload: null,
        canceled: null,
        isInitialMessage: false,
    };
}

export function createBaseMessageAttrs() {
    return {
        id: generateGuid(),
        createdAt: new Date().getTime(),
        hasError: false as const,
        payload: null,
        hidden: false as const,
    };
}

export function isTutorBotMessageInputs(inputs: OutgoingMessageInputs): inputs is TutorBotSystemMessageInputs {
    return inputs.role === 'tutor_bot_system';
}

export function isExplainScreenMessageInputs(inputs: OutgoingMessageInputs): inputs is ExplainScreenMessageInputs {
    return inputs.role === 'tutor_bot_system' && inputs.content === EXPLAIN_SCREEN_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT;
}

export function isReviewLessonMessageInputs(inputs: OutgoingMessageInputs): inputs is ExplainScreenMessageInputs {
    return inputs.role === 'tutor_bot_system' && inputs.content === REVIEW_LESSON_TUTOR_BOT_SYSTEM_MESSAGE_CONTENT;
}

export function isHumanMessageInputs(inputs: OutgoingMessageInputs): inputs is HumanMessageInputs {
    return inputs.role === 'human';
}
