/* eslint-disable func-names */
import angularModule from 'Translation/angularModule/scripts/translation_module';
import moment from 'moment-timezone';
import { DefaultLocale } from 'Translation';
import reactI18nNext, { initializeReactI18N } from 'ReactI18N';
import localeConfig from 'Locale';

/* eslint-disable no-use-before-define */
angularModule.factory('Locale', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');
        const $q = $injector.get('$q');
        const $route = $injector.get('$route');
        const SupportedLocales = $injector.get('Translation.SupportedLocales');
        const UseServerDeterminedLocale = $injector.get('Translation.UseServerDeterminedLocale');
        const $translate = $injector.get('$translate');
        const $rootScope = $injector.get('$rootScope');
        const $location = $injector.get('$location');

        $rootScope.$watch('currentUser.pref_locale', prefLocale => {
            if (prefLocale) {
                Locale.preferredCode = prefLocale;
            } else {
                // reset the private variable so that next time it will read again
                // from serverDeterminedLocale (see getter)
                Locale._preferredCode = null;
            }
        });

        $rootScope.$on('$translateChangeEnd', (_event, lang) => {
            if (!lang || !SupportedLocales.includes(lang)) {
                return;
            }
            Locale.refreshLangAndDir(lang.language);
        });

        const Locale = {
            initialize: () => {
                Locale.initialized = true;
                initializeReactI18N();
                // set the initial activeCode
                Locale.attemptToSetPreferredLanguage();
            },

            // Note: if you support Chinese for regular users,
            // see user.rb ensure_valid_pref_locale and validate_pref_locale
            availablePreferenceLocales() {
                return this.availablePrefLocales;
            },

            getPreferredOrDefaultLocale() {
                // see comment near SupportedLocales in translation_module.js for why we have
                // to be careful not to set this to an unsupported value.
                return SupportedLocales.includes(this.preferredCode) ? this.preferredCode : DefaultLocale;
            },

            attemptToSetLanguage(locale, reloadRoute, rethrowOnError = false) {
                locale = SupportedLocales.includes(locale) ? locale : this.getPreferredOrDefaultLocale();
                this.activeCode = locale;

                const changingLanguage = $translate.use(false) !== locale || reactI18nNext.language !== locale;

                if (changingLanguage) {
                    return $translate
                        .use(locale)
                        .then(() => reactI18nNext.changeLanguage(locale))
                        .then(() => {
                            if (locale === 'ar') {
                                const RouteAssetLoader = $injector.get('Navigation.RouteAssetLoader');
                                return RouteAssetLoader.loadArabicFontDependencies();
                            }
                            return $q.when();
                        })
                        .then(() => {
                            // This will only refresh directives within the ui-view.
                            if (reloadRoute) {
                                $route.reload();
                            }
                            return $q.when();
                        })
                        .then(() =>
                            $q.when().then(() => {
                                this.refreshLangAndDir(locale);
                            }),
                        )
                        .catch(response => {
                            $injector
                                .get('translationErrorLoggerUtility')
                                .logTranslationError(`Error setting ${locale} locale`, {
                                    response: response && response.message,
                                });
                            if (rethrowOnError) {
                                throw response;
                            }
                        });
                }

                // even if we're currently set to that language, refresh the language and dir in the HTML
                this.refreshLangAndDir(locale);
                return $q.when();
            },

            attemptToSetPreferredLanguage(reloadRoute = true) {
                return this.attemptToSetLanguage(this.getPreferredOrDefaultLocale(), reloadRoute);
            },

            // Note: see translation_module.js for where we call this method whenever a locale *changes*
            // This covers a case where the locale could have been changed outside of the setLanguage method above
            refreshLangAndDir(locale) {
                locale = locale.toLowerCase();
                const localeObject = Locale[locale];
                const chineseMonths = [
                    '1月',
                    '2月',
                    '3月',
                    '4月',
                    '5月',
                    '6月',
                    '7月',
                    '8月',
                    '9月',
                    '10月',
                    '11月',
                    '12月',
                ];

                const overridesLookup = {
                    am: {
                        relativeTime: {
                            m: '%d ደቂቃ',
                            h: '%d ሰአት',
                            M: '%d ወር',
                            y: '%d አመት',
                        },
                    },
                    ar: {
                        relativeTime: {
                            m: '%d دقيقة',
                            h: '%d ساعة',
                            M: '%d شهر',
                            y: '%d سنة',
                        },
                    },
                    en: {
                        relativeTime: {
                            m: '%d minute',
                            h: '%d hour',
                            M: '%d month',
                            y: '%d year',
                        },
                    },
                    es: {
                        relativeTime: {
                            m: '%d minuto',
                            h: '%d hora',
                            M: '%d mes',
                            y: '%d año',
                        },
                    },
                    it: {
                        relativeTime: {
                            m: '%d minuto',
                            h: '%d ora',
                            M: '%d mese',
                            y: '%d anno',
                        },
                    },
                    zh: {
                        relativeTime: {
                            m: '%d 分钟',
                            h: '%d 小时',
                            M: '%d 个月',
                            y: '%d 年',
                        },
                        longDateFormat: {
                            LTS: 'HH:mm:ss',
                            LT: 'HH:mm',
                            L: 'YYYY/MM/DD',
                            LL: 'YYYY年M月D日',
                            LLL: 'YYYY年M月D日Ah点mm分',
                            LLLL: 'YYYY年M月D日ddddAh点mm分',
                            l: 'YYYY/M/D',
                            ll: 'YYYY年M月D日',
                            lll: 'YYYY年M月D日 HH:mm',
                            llll: 'YYYY年M月D日dddd HH:mm',
                        },
                        months: chineseMonths,
                        monthsShort: chineseMonths,
                        week: { dow: 1, doy: 4 },
                        weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
                        weekdaysMin: ['日', '一', '二', '三', '四', '五', '六'],
                        weekdaysShort: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                    },
                };

                // moment is giving us deprecation warnings here and telling us to use updateLocale,
                // but if we do then the datetimepicker used in report-date-range blows up. Try changing
                // this to updateLocale and the run the spec for report-date-range and you'll see.
                moment.locale(locale, overridesLookup[locale]);
                $('html')
                    .attr('lang', locale)
                    .attr('dir', localeObject && localeObject.rtl ? 'rtl' : 'ltr');
            },

            defaultLocaleForSignUpCode(signUpCode) {
                // See https://trello.com/c/bmbkgSIT
                // For all FREEMBA signups, we want to force the 'en' locale, the
                // only exception being when the 'locale' query string param
                // is present.
                // For all VALAR signups, we want to force the 'en' locale, see https://trello.com/c/1E7UHA1X
                const forceToENSignUpCodes = ['FREEMBA', 'VALAR'];

                return forceToENSignUpCodes.includes(signUpCode) && !$location.search().locale
                    ? DefaultLocale
                    : this.preferredCode;
            },
            ...localeConfig,
        };

        Object.defineProperty(Locale, 'preferredCode', {
            get() {
                if (!this._preferredCode) {
                    this._preferredCode = UseServerDeterminedLocale ? $window.serverDeterminedLocale : DefaultLocale;
                }
                return this._preferredCode;
            },
            set(languageCode) {
                this._preferredCode = languageCode;

                // In specs, initialize() will not have been called yet.  Prevent
                // calling attemptToSetPreferredLanguage to avoid errors
                if (this.initialized) {
                    this.attemptToSetPreferredLanguage();
                }
            },
            configurable: true,
        });

        return Locale;
    },
]);
