import telephoneValidation from './TelephoneValidation';
import telephoneInput from './TelephoneInput';
import 'Translation/angularModule';
import 'Countries/angularModule';

const telephoneModule = angular.module('FrontRoyal.Telephone', ['Translation', 'FrontRoyal.Countries']);
telephoneModule.directive('telInput', ['$injector', telephoneInput]);
telephoneModule.directive('telValidation', ['$injector', telephoneValidation]);

export default telephoneModule;
